import fetcher, { adminFetcher } from '@lib/fetcher'
import customerEmailMarketingConsentMutation from '@lib/mutations/customer-email-consent-update'
import customerPaymentMethodSendUpdateEmail from '@lib/mutations/customer-payment-method-send-update-email'
import customerSmsConsentUpdateMutation from '@lib/mutations/customer-sms-consent-update'
import customerUpdateMutation from '@lib/mutations/customer-update'
import getCustomerMarketingConsentQuery from '@lib/queries/get-customer-marketing-consent-query'
import getCustomerQuery from '@lib/queries/get-customer-query'
import type { PaymentMethod } from '@lib/types'
import type { Customer, CustomerUserErrors, UserErrors } from '@lib/types/customer'

/**
 * Get customer by accessToken with Shopify Storefront API
 */
export async function getCustomer(customerAccessToken: string): Promise<Customer | null> {
	const customerQuery = await fetcher({
		variables: { customerAccessToken },
		query: getCustomerQuery
	})

	if (customerQuery.customer) {
		const { customer } = customerQuery

		const customerObj = {
			id: customer.id,
			firstName: customer.firstName,
			lastName: customer.lastName,
			createdAt: customer.createdAt,
			phone: customer.phone,
			email: customer.email,
			acceptsMarketing: customer.acceptsMarketing,
			tags: customer.tags
		}

		return customerObj
	}

	return null
}

export type GetCustomerMarketingConsentReturn = Pick<
	Customer,
	'emailMarketingConsent' | 'smsMarketingConsent'
> | null
/**
 * Get customer marketing consent fields using Shopify Admin API
 */
export async function getCustomerMarketingConsent(
	id: Customer['id']
): Promise<GetCustomerMarketingConsentReturn> {
	const customerQuery = await adminFetcher({
		variables: { id },
		query: getCustomerMarketingConsentQuery
	})

	if (customerQuery.customer) {
		const { customer } = customerQuery

		return {
			emailMarketingConsent: customer.emailMarketingConsent,
			smsMarketingConsent: customer.smsMarketingConsent
		}
	}

	return null
}

export async function fetchCustomerMarketingConsent(
	id: Customer['id']
): Promise<GetCustomerMarketingConsentReturn | null> {
	const res = await fetch(`/api/shopify/customer/${encodeURIComponent(id)}/marketing-consent`)

	const responseJson = await res.json()

	if (responseJson.success) {
		return responseJson.data
	}

	return null
}

export async function updateCustomer(
	customerAccessToken: string,
	newCustomerData: Partial<Customer>
): Promise<true | CustomerUserErrors> {
	const updateOrError = await fetcher({
		variables: { customerAccessToken, input: newCustomerData },
		query: customerUpdateMutation
	})

	if (updateOrError?.customerUpdate?.customerUserErrors?.length) {
		const { customerUserErrors: errors } = updateOrError.customerUpdate

		return errors
	}

	return true
}

/**
 * Update customer email marketing consent field using Shopify Admin API
 */
export async function updateCustomerEmailConsent(
	customerId: Customer['id'],
	newEmailConsent: boolean
): Promise<true | UserErrors> {
	const updateOrError = await adminFetcher({
		variables: {
			customerId,
			input: {
				customerId,
				emailMarketingConsent: {
					marketingOptInLevel: 'SINGLE_OPT_IN',
					marketingState: newEmailConsent ? 'SUBSCRIBED' : 'UNSUBSCRIBED'
				}
			}
		},
		query: customerEmailMarketingConsentMutation
	})

	if (updateOrError?.customerEmailMarketingConsentUpdate?.userErrors?.length) {
		const { userErrors: errors } = updateOrError.customerEmailMarketingConsentUpdate

		return errors
	}

	return true
}

/**
 * Update customer SMS marketing consent field using Shopify Admin API
 */
export async function updateCustomerSmsConsent(
	customerId: Customer['id'],
	newSmsConsent: boolean
): Promise<true | UserErrors> {
	const updateOrError = await adminFetcher({
		variables: {
			customerId,
			input: {
				customerId,
				smsMarketingConsent: {
					marketingOptInLevel: 'SINGLE_OPT_IN',
					marketingState: newSmsConsent ? 'SUBSCRIBED' : 'UNSUBSCRIBED'
				}
			}
		},
		query: customerSmsConsentUpdateMutation
	})

	if (updateOrError?.customerSmsMarketingConsentUpdate?.userErrors?.length) {
		const { userErrors: errors } = updateOrError.customerSmsMarketingConsentUpdate

		return errors
	}

	return true
}

/**
 * Send email to update payment method
 *
 * @see https://shopify.dev/api/admin-graphql/2022-04/mutations/customerPaymentMethodSendUpdateEmail
 */
export async function sendPaymentMethodUpdateEmail(
	paymentMethodId: PaymentMethod['id']
): Promise<true | UserErrors> {
	const updateOrError = await adminFetcher({
		variables: {
			customerPaymentMethodId: paymentMethodId
		},
		query: customerPaymentMethodSendUpdateEmail
	})

	if (updateOrError?.customerPaymentMethodSendUpdateEmail?.userErrors?.length) {
		const { userErrors: errors } = updateOrError.customerPaymentMethodSendUpdateEmail

		return errors
	}

	return true
}
