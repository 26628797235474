import { metafieldFragment } from './utils'

const getCustomerOrdersQuery = /* GraphQL */ `
	query getCustomerOrders($customerAccessToken: String!, $numOrders: Int!) {
		customer(customerAccessToken: $customerAccessToken) {
			orders(first: $numOrders, sortKey: PROCESSED_AT, reverse: true) {
				nodes {
					id
					orderNumber
					processedAt

					financialStatus
					fulfillmentStatus
					currentTotalPrice {
						amount
						currencyCode
					}

					subscription: metafield(namespace: "appstle_subscription", key: "details") {
						...metafield
					}

					migratedCreatedAt: metafield(namespace: "order", key: "magento_order_created_at") {
						...metafield
					}

					lineItems(first: 250) {
						nodes {
							title
							currentQuantity

							variant {
								id
								title
								sku
								product {
									handle
								}
								image {
									url
									height
									width
								}
							}
						}
					}
				}
			}
		}
	}

	${metafieldFragment}
`
export default getCustomerOrdersQuery
