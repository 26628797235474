import createCart from '@lib/cart/create-cart'
import getCartId from '@lib/cart/get-cart-id'
import fetcher from '@lib/fetcher'
import cartLinesAddMutation from '@lib/mutations/cart-lines-add'
import type { ProductVariant } from '@lib/types/product'

import { ShopifyWebStorefrontClient } from '@components/cart/OnwardStorefrontClient'

export type AddItemParams = {
	variantId: ProductVariant['id']
	quantity: number
	sellingPlanId?: string | null
}

/**
 * Add cart items using Shopify's cart API
 *
 * @see https://shopify.dev/api/examples/cart
 */
export default async function addItem({ variantId, quantity, sellingPlanId }: AddItemParams) {
	try {
		const lineItems = [
			{
				merchandiseId: variantId,
				quantity,
				...(sellingPlanId ? { sellingPlanId } : {})
			}
		]
		const cartId = getCartId()

		if (!cartId) {
			return await createCart(lineItems)
		}

		const { cartLinesAdd } = await fetcher({
			query: cartLinesAddMutation,
			variables: {
				cartId,
				lines: lineItems,
				sellingPlanId
			}
		})

		if (window.onwardApp && window.onwardClient) {
			window.onwardApp.cartChanged(ShopifyWebStorefrontClient.parseCart(cartLinesAdd.cart))
		}
		return cartLinesAdd
	} catch (error) {
		console.error('Failed to add item to cart:', error)
		// You can add more specific error handling or logging here as needed
		throw new Error('Failed to add item to cart')
	}
}
