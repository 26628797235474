import { cartDetailsFragment } from '@lib/queries/get-cart-query'

/**
 * @see https://shopify.dev/api/examples/cart
 */
const cartCreate = /* GraphQL */ `
	mutation cartCreate($input: CartInput = {}) {
		cartCreate(input: $input) {
			cart {
				...cartDetails
			}
		}
	}

	${cartDetailsFragment}
`

export default cartCreate
