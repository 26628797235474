import getCartId from '@lib/cart/get-cart-id'
import fetcher from '@lib/fetcher'
import cartLinesUpdateMutation from '@lib/mutations/cart-line-item-update'

import { ShopifyWebStorefrontClient } from '@components/cart/OnwardStorefrontClient'

import type { UpdateItemParams } from './update-item'

/**
 * Update cart items using Shopify's cart API
 *
 * @see https://shopify.dev/api/examples/cart
 */
export default async function updateItems(items: UpdateItemParams[]) {
	const lines = items.map(({ id, variantId, quantity, sellingPlanId }) => ({
		id,
		merchandiseId: variantId,
		quantity,
		sellingPlanId
	}))

	const cartId = getCartId()

	if (!cartId) {
		return null
	}

	const { cartLinesUpdate } = await fetcher({
		query: cartLinesUpdateMutation,
		variables: {
			cartId,
			lines
		}
	})

	if (window.onwardApp && window.onwardClient) {
		window.onwardApp.cartChanged(ShopifyWebStorefrontClient.parseCart(cartLinesUpdate.cart))
	}

	return cartLinesUpdate
}
