/**
 * Abstract Date.toLocaleDateString
 */
// eslint-disable-next-line import/prefer-default-export
export function formatDateString(
	dateString: string,
	options: Intl.DateTimeFormatOptions = {
		dateStyle: 'long' // long date style as default
	}
) {
	const date = new Date(dateString)

	if (date.toString() === 'Invalid Date') return null

	if (date instanceof Date) {
		return date.toLocaleDateString(process.env.NEXT_PUBLIC_LANG, options)
	}

	return null
}

export function extractMonthYear(dateString: string) {
	const date = new Date(dateString)

	if (date.toString() === 'Invalid Date') return null

	const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'long' }
	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

	return formattedDate.replace(/ (\d+)/, " '$1")
}

export function formatDateNumber(
	dateNumber: number,
	options: Intl.DateTimeFormatOptions = {
		dateStyle: 'long' // long date style as default
	}
) {
	const date = new Date(dateNumber)

	if (date.toString() === 'Invalid Date') return null

	if (date instanceof Date) {
		return date.toLocaleDateString(process.env.NEXT_PUBLIC_LANG, options)
	}

	return null
}

export function getDaysDifference(startDate: Date, endDate: Date) {
	const dayInMs = 1000 * 60 * 60 * 24
	return (endDate.getTime() - startDate.getTime()) / dayInMs
}

export function getPassedCompleteMonths(date: Date) {
	const now = new Date()
	const passedYears = now.getFullYear() - date.getFullYear()
	const passedMonths = 12 * passedYears + now.getMonth() - date.getMonth()

	const d = new Date(date)
	d.setMonth(d.getMonth() + passedMonths)

	return passedMonths - (now < d ? 1 : 0)
}

export function calculateHoursSince(dateString: string): number {
	// Parse the order creation date
	const orderDate = new Date(dateString)
	// Get the current date and time
	const currentDate = new Date()

	// Calculate the difference in milliseconds
	const differenceInMillis = currentDate.getTime() - orderDate.getTime()

	// Convert the difference from milliseconds to hours
	const differenceInHours = differenceInMillis / (1000 * 60 * 60)

	// Return the difference in hours
	return differenceInHours
}

export function calculateHoursDifference(fromDateString: string, toDateString: string): number {
	// Parse the order creation date
	const fromDate = new Date(fromDateString)
	// Get the current date and time
	const toDate = new Date(toDateString)

	// Calculate the difference in milliseconds
	const differenceInMillis = toDate.getTime() - fromDate.getTime()

	// Convert the difference from milliseconds to hours
	const differenceInHours = differenceInMillis / (1000 * 60 * 60)

	// Return the difference in hours
	return differenceInHours
}

export function getNumberDaysAgoDate(numberOfDaysAgo: number): Date {
	// Get the current date and time
	const currentDate = new Date()

	// Subtract numberOfDaysAgo days (numberOfDaysAgo days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
	const twentyDaysAgo = new Date(currentDate.getTime() - numberOfDaysAgo * 24 * 60 * 60 * 1000)

	// Return the Date object representing the date 20 days ago
	return twentyDaysAgo
}
