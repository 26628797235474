import { createContext, useCallback, useContext, useMemo, useState } from 'react'

type Toast = { title: string; message: string; key: number }
type SetToast = (title: string, message: string, time?: number) => void
type ToastContextType = {
	toasts: Toast[]
	setToasts: (toasts: Toast[]) => void
	toast: SetToast
}

// Default toast time before it gets removed
const defaultToastTime = 5000 // ms

// Toast Context
export const ToastContext = createContext<ToastContextType>({
	toasts: [],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	toast: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setToasts: () => {}
})

// Get the current toasts
export function useToasts() {
	return useContext(ToastContext)
}

// Create a new toast
export default function useToast() {
	return useContext(ToastContext).toast
}

// Toast Provider
export function ToastProvider({ children }: any) {
	const [toasts, setToasts] = useState<Toast[]>([])

	// Show toast fn
	const toast: SetToast = useCallback(
		(title, message, time) => {
			// Random key for the toast — used to display multiple toasts without bugs
			const key = Math.random()
			setToasts((prevToasts) => [...prevToasts, { title, message, key }])

			// Remove the toast from the screen after some time
			setTimeout(() => {
				setToasts(toasts.filter((t) => t.key !== key))
			}, time || defaultToastTime)
		},
		[setToasts, toasts]
	)

	// Reset/configure toasts fn
	const setNewToasts = useCallback((newToasts: any) => {
		setToasts(newToasts)
	}, [])

	// Memoize context value
	const contextValue = useMemo(
		() => ({ toast, toasts, setToasts: setNewToasts }),
		[toasts, toast, setNewToasts]
	)

	return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>
}
