import type { RemoveItemParams } from './remove-item'
import removeItem from './remove-item'
import useCart, { formatCart } from './use-cart'

/**
 * A hook to remove cart items and revalidate cart's data
 */
export default function useRemoveItem() {
	const { revalidate } = useCart()

	const removeItemAndRevalidateCart = async ({ id }: RemoveItemParams) => {
		await revalidate(
			async (currentValue) => {
				if (!currentValue) {
					return { cart: null, items: null, itemCount: 0 }
				}

				const cartData = await removeItem({ id })
				const newCartData = formatCart(cartData)

				return newCartData
			},
			{
				rollbackOnError: true,
				revalidate: false, // don't revalidate, because the updater function returns the new cart.
				optimisticData: (currentData) =>
					currentData
						? {
								...currentData,
								items: currentData?.items?.filter((item) => item.id !== id)
						  }
						: { cart: null, items: [], itemCount: 0 }
			}
		)
	}

	return removeItemAndRevalidateCart
}
