import { useSession } from 'next-auth/react'

import useSWR from 'swr'

import { getCustomer } from '@lib/operations/customer'
import type { Customer } from '@lib/types/customer'

type UseCustomerReturn = {
	customer: Customer | undefined | null
	loading: boolean
	revalidate: () => void
}

export default function useCustomer(): UseCustomerReturn {
	const { data: session } = useSession()

	const accessToken = session?.shopifyAccessToken

	const swrKey = accessToken ? `/customer/${accessToken}` : null

	const {
		data: customer,
		isValidating,
		mutate
	} = useSWR(swrKey, () => getCustomer(accessToken as string))

	return { customer, loading: isValidating, revalidate: mutate }
}
