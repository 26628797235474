import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import urls from '@data/i18n/urls.json'

import { searchUrl } from '@lib/constants'
import type { CartItem } from '@lib/types/cart'
import type { MenuData } from '@lib/types/menu'

import BagShoppingIcon from '@components/icons/bag-shopping'
import BarsIcon from '@components/icons/bars'
import MagnifyingGlassIcon from '@components/icons/magnifying-glass'
import UserIcon from '@components/icons/user'

import Logo from './Logo'
import MobileMenuSlider from './MobileMenuSlider'

type MobileavParams = {
	toggleCart: Dispatch<SetStateAction<boolean>>
	menuData: MenuData
	cartItems: CartItem[] | null | undefined
	itemCount: number
	isLoggedIn: boolean
}

export default function MobileNav({
	toggleCart,
	menuData,
	cartItems,
	itemCount,
	isLoggedIn
}: MobileavParams) {
	// Menu open state
	const [menuOpen, toggleMenuOpen] = useState(false)

	const router = useRouter()

	// Handle close menu on route change
	useEffect(() => {
		const handleRouteChange = () => {
			toggleMenuOpen(false)
		}

		router.events.on('routeChangeStart', handleRouteChange)

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	})

	return (
		<nav className="lg:hidden">
			<div className="container flex items-center px-3">
				<div className="flex items-center">
					{/* Hamburger menu */}
					<button
						className="px-4 py-6 text-lg"
						aria-label="Toggle navigation"
						type="button"
						onClick={() => toggleMenuOpen(true)}
					>
						<BarsIcon />
					</button>

					{/* Profile */}
					<Link href={urls.subNav.profile.url} prefetch={false}>
						<a
							className={`px-4 py-6 ${isLoggedIn ? 'text-bbx-spark' : ''}`}
							aria-label="Profile"
						>
							<UserIcon />
						</a>
					</Link>
				</div>

				{/* Logo */}
				<Link href="/" prefetch={false}>
					<a className="inline-flex justify-center flex-1" title="Birchbox">
						<Logo className="w-16 sm:w-20 fill-bbx-style" />
					</a>
				</Link>

				<div className="flex items-center">
					<Link href={searchUrl} prefetch={false}>
						<a className="relative px-4 py-6" aria-label="Search">
							<MagnifyingGlassIcon classes="w-[17px] fill-bbx-style" />
						</a>
					</Link>

					{/* Recursive menu slider */}
					<MobileMenuSlider
						slideIn={menuOpen}
						items={menuData?.items}
						level={1}
						closeMenu={() => toggleMenuOpen(false)}
					/>

					{/* Toggle cart button */}
					<button
						className={`flex items-center relative px-4 py-6 ${
							Array.isArray(cartItems) && cartItems?.length > 0 ? 'text-bbx-spark pr-1' : ''
						}`}
						type="button"
						aria-label="Cart"
						onClick={() => toggleCart((prev) => !prev)}
					>
						<BagShoppingIcon classes="fill-bbx-style w-4" />

						{/* Items in cart count */}
						{Array.isArray(cartItems) && cartItems?.length > 0 && (
							<span className="w-6 ml-2 -mb-1 font-medium text-center rounded bg-bbx-pearl-light">
								{itemCount}
							</span>
						)}
					</button>
				</div>
			</div>
		</nav>
	)
}
