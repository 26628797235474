import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'

import { useSession } from 'next-auth/react'

import useCart from '@lib/cart/use-cart'
import useCustomer from '@lib/hooks/useCustomer'
import getSurvey from '@lib/subscription-box/get-survey'
import type {
	SurveyParams,
	SurveyQuestion,
	SurveySubmissionsState
} from '@lib/types/subscription-box'
import setCookie from '@lib/utils/set-cookies'

// Typeof return useSurveyStateManager
type UseManagerResult = ReturnType<typeof useSurveyStateManager>

// Survey context with initial values
const SurveyContext = createContext<UseManagerResult>({
	survey: {
		surveyId: 0,
		surveyQuestions: []
	},
	surveyState: {
		currentIndex: 0,
		translateDirection: 'forwards',
		surveyProgress: 0
	},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	nextQuestion: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	previousQuestion: () => {},
	surveySubmissions: [],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleSurveySubmission: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleSurveySkipSubmission: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleSubmitSurvey: () => {},
	animateInReady: true,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setAnimateInReady: () => {},
	nextButtonDisabled: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setNextButtonDisabled: () => {}
})

// Reducer action type
type ActionType = { type: 'NEXT' | 'PREVIOUS' }

type SurveyStateParams = {
	currentIndex: number
	translateDirection: 'forwards' | 'backwards'
	surveyProgress: number
}

// Manages survey content state
function useSurveyStateManager(survey: SurveyParams): {
	survey: SurveyParams
	surveyState: SurveyStateParams
	nextQuestion: () => void
	previousQuestion: () => void
	surveySubmissions: SurveySubmissionsState[]
	handleSurveySubmission: (
		surveyQuestion: SurveyQuestion,
		newVal:
			| {
					optionValue: string
					id?: number
			  }
			| any[]
	) => void
	handleSurveySkipSubmission: (surveyQuestionId: string) => void
	handleSubmitSurvey: (shopifyAccessToken: string) => void
	animateInReady: boolean
	setAnimateInReady: Dispatch<SetStateAction<boolean>>
	nextButtonDisabled: boolean
	setNextButtonDisabled: Dispatch<SetStateAction<boolean>>
} {
	const { addSurveyAnswerToCartAttributes } = useCart() || {}
	const { surveyId, surveyQuestions } = survey || {}

	// Survey reducer
	const [surveyState, dispatch] = useReducer(
		(state: SurveyStateParams, action: ActionType): SurveyStateParams => {
			const { currentIndex } = state

			switch (action.type) {
				case 'NEXT': {
					// If last question in survey
					if (
						surveyQuestions?.[currentIndex]?.id ===
						surveyQuestions?.[surveyQuestions.length - 1]?.id
					) {
						return state
					}

					const newSurveyProgress = (currentIndex + 2) / ((surveyQuestions?.length || 0) + 1)
					return {
						currentIndex: currentIndex + 1,
						translateDirection: 'forwards',
						surveyProgress: newSurveyProgress
					}
				}
				case 'PREVIOUS': {
					// If first question in survey
					if (surveyQuestions?.[currentIndex]?.id === surveyQuestions?.[0]?.id) {
						return state
					}

					const newSurveyProgress = currentIndex / ((surveyQuestions?.length || 0) + 1)
					return {
						currentIndex: currentIndex - 1,
						translateDirection: 'backwards',
						surveyProgress: newSurveyProgress
					}
				}
				default:
					return state
			}
		},
		{
			currentIndex: 0,
			translateDirection: 'forwards',
			surveyProgress: 1 / ((surveyQuestions?.length || 0) + 1)
		}
	)

	const nextQuestion = useCallback(() => {
		dispatch({
			type: 'NEXT'
		})
	}, [])

	const previousQuestion = useCallback(() => {
		dispatch({
			type: 'PREVIOUS'
		})
	}, [])

	// User survey submissions - starts as array of empty objects
	const [surveySubmissions, setSurveySubmissions] = useState<SurveySubmissionsState[]>(
		surveyQuestions?.map(({ id, body }) => ({
			question: body,
			questionId: id,
			response: [],
			optionId: [],
			additionalData: ''
		}))
	)

	const handleSurveySubmission = useCallback(
		(surveyQuestion: any, newVal: any) => {
			setSurveySubmissions((prev: SurveySubmissionsState[]) => {
				// For multiselect
				if (surveyQuestion?.type === 'multiselect' && Array.isArray(newVal)) {
					// ID of the question option
					const idArray = newVal
						?.filter(({ active }: { active: boolean }) => active)
						?.map(({ id }: { id: number }) => id)

					// Names with spaces
					const valueArray = newVal
						?.filter(({ active }: { active: boolean }) => active)
						?.map(({ optionValue }: { optionValue: string }) => optionValue)

					const newSurveySubmissionsState = prev.map(
						({ question, questionId, response, optionId, additionalData }) => ({
							question,
							questionId,
							response: surveyQuestion?.id === questionId ? valueArray : response,
							optionId: surveyQuestion?.id === questionId ? idArray : optionId,
							additionalData
						})
					)

					return newSurveySubmissionsState
				}
				// For RangeSlider and RadioSelect
				const newState = prev.map(
					({ question, questionId, response, optionId, additionalData }) => ({
						question,
						questionId,
						response: surveyQuestion?.id === questionId ? [newVal?.optionValue] : response,
						optionId: surveyQuestion?.id === questionId ? [newVal?.id] : optionId,
						additionalData
					})
				)

				return newState
			})
		},
		[setSurveySubmissions]
	)

	// Resets current question value when skipped

	const handleSurveySkipSubmission = useCallback(
		(surveyQuestionId: any) => {
			setSurveySubmissions((prev: SurveySubmissionsState[]) =>
				prev.map(({ question, questionId, response, additionalData, optionId }) => ({
					question,
					questionId,
					response: surveyQuestionId === questionId ? [] : response,
					optionId,
					additionalData
				}))
			)
		},
		[setSurveySubmissions]
	)

	// Waits for previous animation to finish before new animation starts
	const [animateInReady, setAnimateInReady] = useState(true)

	// Next button disabled status
	const [nextButtonDisabled, setNextButtonDisabled] = useState(false)

	// Submit survey to BBX APIs
	const handleSubmitSurvey = useCallback(
		async (customerId: string) => {
			if (customerId) {
				const surveyAnswers = surveySubmissions.map(({ questionId, response }) => ({
					question_id: questionId,
					answer: response.toString()
				}))
				await addSurveyAnswerToCartAttributes(surveyAnswers)
				setCookie('bbSurveySubmitted', 'true', 48)
			}
		},
		[surveyId, surveySubmissions]
	)

	return {
		survey,
		surveyState,
		nextQuestion,
		previousQuestion,
		surveySubmissions,
		handleSurveySubmission,
		handleSurveySkipSubmission,
		handleSubmitSurvey,
		animateInReady,
		setAnimateInReady,
		nextButtonDisabled,
		setNextButtonDisabled
	}
}

// Survey provider
export function Survey({
	children,
	surveyType
}: {
	children: ReactNode
	surveyType: 'new' | 'returning' | 'none'
}) {
	const { data: session } = useSession()
	const { customer } = useCustomer() || {}

	// Survey questions - fetch once the subscription box is in cart
	const [surveyData, setSurvey] = useState<ReturnType<typeof getSurvey> | null>(null)

	useEffect(() => {
		if (surveyType !== 'none' && !surveyData) {
			const fetchSurvey = async () => {
				const userSurvey = getSurvey()

				setSurvey(userSurvey)
			}

			fetchSurvey()
		}

		return () => {
			// Do nothing, but prevent the survey from being re-fetched
		}
	}, [customer, session])

	return surveyData ? <SurveyProvider surveyData={surveyData}>{children}</SurveyProvider> : null
}

// eslint-disable-next-line react/no-multi-comp
function SurveyProvider({
	children,
	surveyData
}: {
	children: ReactNode
	surveyData: SurveyParams
}) {
	return (
		<SurveyContext.Provider value={useSurveyStateManager(surveyData)}>
			{children}
		</SurveyContext.Provider>
	)
}

// Returns survey
export const useSurvey = (): UseManagerResult['survey'] => {
	const { survey } = useContext(SurveyContext)
	return survey
}

// Returns survey state
export const useSurveyState = (): UseManagerResult['surveyState'] => {
	const { surveyState } = useContext(SurveyContext)
	return surveyState
}

// Go to next question in survey
export const useNextQuestion = (): UseManagerResult['nextQuestion'] => {
	const { nextQuestion } = useContext(SurveyContext)
	return nextQuestion
}

// Go to previous question in survey
export const usePreviousQuestion = (): UseManagerResult['previousQuestion'] => {
	const { previousQuestion } = useContext(SurveyContext)
	return previousQuestion
}

// Gets current survey submissions
export const useSurveySubmissions = (): UseManagerResult['surveySubmissions'] => {
	const { surveySubmissions } = useContext(SurveyContext)
	return surveySubmissions?.filter((submission) => submission?.response)
}

// Sets current survey submissions
export const useHandleSurveySubmissions = (): UseManagerResult['handleSurveySubmission'] => {
	const { handleSurveySubmission } = useContext(SurveyContext)
	return handleSurveySubmission
}

// Submits survey
export const useHandleSubmitSurvey = (): UseManagerResult['handleSubmitSurvey'] => {
	const { handleSubmitSurvey } = useContext(SurveyContext)
	return handleSubmitSurvey
}

// Sets current survey question to ''
export const useHandleSkipSubmission = (): UseManagerResult['handleSurveySkipSubmission'] => {
	const { handleSurveySkipSubmission } = useContext(SurveyContext)
	return handleSurveySkipSubmission
}

// If animation is ready to happen
export const useAnimateInReady = (): UseManagerResult['animateInReady'] => {
	const { animateInReady } = useContext(SurveyContext)
	return animateInReady
}

// Set if animation is ready to happen
export const useSetAnimationReady = (): UseManagerResult['setAnimateInReady'] => {
	const { setAnimateInReady } = useContext(SurveyContext)
	return setAnimateInReady
}

// If next button is disabled
export const useNextButtonDisabled = (): UseManagerResult['nextButtonDisabled'] => {
	const { nextButtonDisabled } = useContext(SurveyContext)
	return nextButtonDisabled
}

// Set if next button is disabled
export const useSetNextButtonDisabled = (): UseManagerResult['setNextButtonDisabled'] => {
	const { setNextButtonDisabled } = useContext(SurveyContext)
	return setNextButtonDisabled
}
