import surveyQuestionsPrismicPage from '@data/surveyQuestions.json'

import type { SurveyParams } from '@lib/types/subscription-box'

const getSurvey = () => {
	// filter to show only display = true questions
	const questions = surveyQuestionsPrismicPage.body.filter((question) => question.primary.display)

	const survey: SurveyParams = {
		surveyId: 1,
		surveyQuestions: questions.map((question, idx) => ({
			id: question.primary.question_id,
			displayOptions: null,
			type: question.primary.option_type === 'Single Choice' ? 'radio_select' : 'multiselect',
			body: question.primary.question[0].text,
			surveyAnswerOptions: question.items.map((option, optionIdx) => ({
				id: idx * 100 + optionIdx + 1,
				optionLabel: option.option_name,
				// FIXME: ensure that option_id is available and valid in Prismic if possible
				optionValue: option.option_id || option.option_name.toLowerCase().replaceAll(' ', '_'),
				// ts ignore as option_image sometimes is { } from prismic
				// @ts-ignore
				optionImage: option.option_image.url || null, // option.option_image
				position: idx + 1,
				status: 'enabled'
			}))
		}))
	}

	return survey
}

export default getSurvey
