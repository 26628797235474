import { Fragment, useEffect, useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import NavTextNodes from '@data/i18n/nav.json'
import membersMarketData from '@data/memberMarket.json'

import { colUrl, lang } from '@lib/constants'
import type { MenuData } from '@lib/types/menu'

import Logo from './Logo'

type MobileMenuSliderParams = {
	items: MenuData['items']
	slideIn: boolean
	closeMenu: () => void
	closeSlider?: () => void
	parentTitle?: string
	level?: number
}
export default function MobileMenuSlider({
	items,
	slideIn,
	parentTitle,
	level,
	closeSlider,
	closeMenu
}: MobileMenuSliderParams) {
	const [displayChildren, setDisplayChildren] = useState<{ [key: string]: boolean | undefined }>({})

	const currentDate = new Date()
	const startDate = new Date(membersMarketData.start_date)
	const endDate = new Date(membersMarketData.end_date)
	const isMambersMarketCurrentlyOpen =
		currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()

	const splitUrl = (url: string) => {
		const relativeUrl = url.split('myshopify.com', 2)[1]

		return relativeUrl
	}

	useEffect(() => {
		if (!slideIn) {
			setDisplayChildren({})
		}
	}, [slideIn])

	return (
		<div
			className="mobile-nav"
			style={{ transform: slideIn ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)' }}
		>
			<div className="border-b border-gray-200">
				<div className="container flex items-center justify-between">
					{level === 1 ? (
						//  Logo
						<Link href="/" prefetch={false}>
							<a className="inline-block py-[22px] pl-3 pr-6 -ml-3" title="Birchbox">
								<Logo className="w-16 sm:w-20 fill-bbx-style" />
							</a>
						</Link>
					) : (
						// Parent title with back button
						<button type="button" className="flex items-center gap-x-3" onClick={closeSlider}>
							<i className="text-xl fa-light fa-arrow-left-long" />

							{parentTitle && <span className="text-lg font-medium">{parentTitle}</span>}
						</button>
					)}

					{/* Close menu */}
					<button type="button" className="p-2 text-lg" aria-label="Close" onClick={closeMenu}>
						<i className="fa-light fa-xmark" />
					</button>
				</div>
			</div>

			{/* Nav items */}
			{Array.isArray(items) && items.length > 0 ? (
				<div className="container flex flex-col flex-1 py-5 overflow-auto gap-y-5">
					{lang === 'en-us' ? (
						<>
							{/* Hard-coded links for US */}
							{/* <Link href={`${colUrl}/best-sellers`}>
								<a className="flex justify-between  items-center py-0.5">
									Bestsellers
								</a>
							</Link>
							<Link href={`${colUrl}/makeup`}>
								<a className="flex justify-between  items-center py-0.5">
									Makeup
								</a>
							</Link>
							<Link href={`${colUrl}/hair`}>
								<a className="flex justify-between  items-center py-0.5">Hair</a>
							</Link>
							<Link href={`${colUrl}/skincare`}>
								<a className="flex justify-between  items-center py-0.5">Skin</a>
							</Link>
							<Link href={`${colUrl}/body`}>
								<a className="flex justify-between  items-center py-0.5">Body</a>
							</Link> */}
							<Link href="/all-plans">
								<a className="flex justify-between  items-center py-0.5">Subscription Box</a>
							</Link>
							<Link href="/members-market">
								<a className="flex items-center py-0.5">
									{isMambersMarketCurrentlyOpen && (
										<span className="bg-[#72BF95] text-white font-bold text-[12px] px-[6px] mr-2">
											LIVE
										</span>
									)}
									Members Market
								</a>
							</Link>
							<Link href={`${colUrl}/gifting`}>
								<a className="flex justify-between  items-center py-0.5">Gifting</a>
							</Link>
						</>
					) : (
						items.map((item) => {
							if (Array.isArray(item?.items) && item.items.length > 0) {
								return (
									<Fragment key={item?.id}>
										<button
											type="button"
											className="flex justify-between items-center py-0.5"
											onClick={() => setDisplayChildren((prev) => ({ ...prev, [item?.id]: true }))}
										>
											{item?.title}
											<i className="fa-light fa-chevron-right" />
										</button>

										<MobileMenuSlider
											items={item?.items}
											slideIn={!!displayChildren[item?.id]}
											parentTitle={item?.title}
											closeSlider={() =>
												setDisplayChildren((prev) => ({ ...prev, [item?.id]: false }))
											}
											closeMenu={closeMenu}
										/>
									</Fragment>
								)
							}

							if (splitUrl(item?.url)?.startsWith('/')) {
								return (
									<Link href={splitUrl(item?.url)} key={item?.id}>
										<a className="relative flex items-center space-x-6">
											{item.image && (
												<Image
													src={item.image.url}
													width={85}
													height={85}
													objectFit="cover"
													alt={item.title}
												/>
											)}
											<span>{item?.title}</span>
										</a>
									</Link>
								)
							}

							return null
						})
					)}
					<hr />
					<Link href={NavTextNodes.theMagUrl} prefetch={false}>
						<a className="flex justify-between items-center py-0.5">{NavTextNodes.theMag}</a>
					</Link>
				</div>
			) : null}
		</div>
	)
}
