import { useSession } from 'next-auth/react'

import { useUpdateEffect } from 'react-use'
import useSWR from 'swr'

import useCart from '@lib/cart/use-cart'
import { lang } from '@lib/constants'

export default function useGetCheckoutUrl() {
	const { cart } = useCart() || {}
	const { data: session } = useSession()

	const { data: checkOutUrl, mutate } = useSWR('/checkout-url', getCheckoutUrl, {
		fallbackData: '/',
		revalidateIfStale: true,
		revalidateOnFocus: true,
		revalidateOnMount: true
	})

	async function getCheckoutUrl() {
		if (cart) {
			// If user is logged in, sign them into Shopify as well and check out
			if (session) {
				const cartUrl = cart?.checkoutUrl.split('/cart')
				if (cartUrl.length === 2) {
					const res = await fetch(`/api/shopify/sign-in?redirectPath=/cart${cartUrl[1]}`)

					const signInObj = await res.json()

					let redirectUrl = signInObj.url

					// US shopify url
					const shopifyBaseUrl = process.env.NEXT_PUBLIC_STORE_DOMAIN
					if (redirectUrl.includes(shopifyBaseUrl)) {
						const checkoutUrls = {
							'en-us': 'checkout.birchbox.com',
							'es-es': 'checkout.birchbox.es',
							'en-gb': 'checkout.birchbox.co.uk'
						}
						let checkoutUrl = checkoutUrls[lang]
						if (process.env.VERCEL_ENV !== 'production') {
							checkoutUrl = process.env.NEXT_PUBLIC_STORE_DOMAIN!
						}
						redirectUrl = redirectUrl.replace(shopifyBaseUrl, checkoutUrl)
					}

					return redirectUrl
				}
			}

			// else continue to checkout without signing in
			else {
				return cart.checkoutUrl
			}
		}

		return '/'
	}

	useUpdateEffect(() => {
		mutate()
	}, [session, cart])

	return checkOutUrl
}
