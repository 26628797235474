import shouldHaveStock from '@lib/product/should-have-stock'
import shouldHideVariant from '@lib/product/should-hide-variant'
import type {
	Collection,
	CollectionProduct,
	CollectionProductSample,
	Collections,
	FilterValue,
	FilterValues,
	Filters
} from '@lib/types'
import type {
	CollectionProductRaw,
	CollectionRaw,
	CollectionsRaw,
	FilterRaw,
	FilterValueRaw,
	FilterValuesRaw,
	FiltersRaw,
	SamplesFilterKeyType
} from '@lib/types/raw'

import { normalizeImages } from './generic'
import { normalizeMetafield } from './product'

/**
 * Normalize collection
 */
export const normalizeCollections = ({ edges }: CollectionsRaw): Collections =>
	edges?.map(({ node }) => normalizeCollection(node))

export const normalizeCollection = (collection: CollectionRaw): Collection => ({
	id: collection.id,
	name: collection.title,
	handle: collection.handle,
	descriptionHtml: collection.descriptionHtml,
	image: collection.image,
	seo: collection.seo
})

export const normalizeCollectionProducts = (products: CollectionProductRaw[]) =>
	products.reduce((acc, product: CollectionProductRaw) => {
		const normalizedProduct = normalizeCollectionProduct(product)

		if (normalizedProduct) {
			return [...acc, normalizedProduct]
		}
		return acc
	}, [] as CollectionProduct[])

export const normalizeCollectionProduct = ({
	node: product
}: CollectionProductRaw): CollectionProduct | null => {
	if (!product) return null
	const hasOnlyHiddenProducts = product?.variants.nodes.every(shouldHideVariant) || false
	const hasStock = product?.variants.nodes.some(shouldHaveStock) || false

	if (hasOnlyHiddenProducts) return null
	return {
		id: product.id,
		name: product.title,
		handle: product.handle,
		vendor: product.vendor,
		type: product.productType,
		images: normalizeImages(product.images, product.title),
		price:
			product.variants.nodes
				.filter((variant) => !shouldHideVariant(variant) && Number(variant.priceV2.amount) > 0)
				?.sort((a, b) => Number(a?.priceV2?.amount) - Number(b?.priceV2?.amount))?.[0]?.priceV2 ||
			product.priceRange.minVariantPrice,
		compareAtPrice:
			product.variants.nodes
				.filter(
					(variant) => !shouldHideVariant(variant) && Number(variant?.compareAtPriceV2?.amount) > 0
				)
				?.sort(
					(a, b) => Number(a?.compareAtPriceV2?.amount) - Number(b?.compareAtPriceV2?.amount)
				)?.[0]?.compareAtPriceV2 || product.priceRange.minVariantPrice,
		avgRating: normalizeMetafield(product.avgRating),
		numReviews: normalizeMetafield(product.numReviews),
		collections: product.collections.edges.map((edge) => edge.node.handle),
		hasStock
	}
}

export const normalizeCollectionProductSamples = (
	products: CollectionProductRaw[],
	filter: SamplesFilterKeyType,
	stockTolerance = 0
) =>
	products.reduce((acc, product: CollectionProductRaw) => {
		const normalizedProduct = normalizeCollectionProductSample(product, filter, stockTolerance)

		if (normalizedProduct) {
			return [...acc, normalizedProduct]
		}
		return acc
	}, [] as CollectionProductSample[])

export const normalizeCollectionProductSample = (
	{ node: product }: CollectionProductRaw,
	filter: SamplesFilterKeyType,
	stockTolerance = 0
): CollectionProductSample | null => {
	if (!product) return null
	const sampleVariants = product?.variants.nodes
		.map((variant) => ({
			...variant,
			hasStock: variant.quantityAvailable > stockTolerance,
			isNMC: variant.isNMC?.value.toLowerCase().trim() === 'true',
			isRMC: variant.isRMC?.value.toLowerCase().trim() === 'true',
			// FIXME: use graphql based types instead of custom built types
			image: variant.image || null
		}))
		.filter(({ isNMC, isRMC }) => {
			// Filter variants to only consider NMC or RMC variants, based on the filter
			switch (filter) {
				case 'NMC':
					return isNMC
				case 'RMC':
					return isRMC
				default:
					return isNMC || isRMC
			}
		})

	if (!sampleVariants.length) return null

	return {
		id: product.id,
		// If there is less than 10 available samples, consider it as OOS
		sampleVariants,
		name: product.title,
		handle: product.handle,
		vendor: product.vendor,
		type: product.productType,
		images: normalizeImages(product.images, product.title),
		avgRating: normalizeMetafield(product.avgRating),
		numReviews: normalizeMetafield(product.numReviews)
	}
}

export const normalizeFilters = (filters: FiltersRaw): Filters =>
	filters.map((filter) => normalizeFilter(filter))

/**
 * Normalize collection filter
 */
export const normalizeFilter = (filter: FilterRaw) => ({
	id: filter.id,
	label: filter.label,
	type: filter.type,
	values: normalizeFilterValues(filter.values)
})

const normalizeFilterValues = (values: FilterValuesRaw): FilterValues =>
	values.map((value) => normalizeFilterValue(value))

/**
 * Normalize filter value
 */
const normalizeFilterValue = (value: FilterValueRaw): FilterValue => ({
	id: value.id,
	label: normalizeFilterValueLabel(value.label),
	count: value.count,
	input: normalizeFilterValueInput(value.input)
})

/**
 * Parse filter label in case it's a JSON string
 */
const normalizeFilterValueLabel = (label: FilterValueRaw['label']): FilterValue['label'] => {
	let normalizedLabel = label

	// parse filter value if it's a JSON string, else just ignore it
	try {
		normalizedLabel = JSON.parse(label)
	} catch (parseError) {
		// if parse errors it means the value is not a JSON, then
		// do nothing since it's already parsed
	}

	return normalizedLabel
}

/**
 * Parse and normalize filter input value since it's a JSON string
 */
const normalizeFilterValueInput = (input: FilterValueRaw['input']): FilterValue['input'] => {
	const parsedInput = JSON.parse(input)

	return parsedInput
}
