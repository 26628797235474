import type { AddItemParams } from './add-item'
import addItems from './add-items'
import useCart from './use-cart'

/**
 * A hook to add cart items and revalidate cart's data
 */
export default function useAddItems() {
	const { revalidate } = useCart()

	const addItemsAndRevalidateCart = async (items: AddItemParams[]) => {
		const resp = await addItems(items)

		await revalidate()
		return resp
	}

	return addItemsAndRevalidateCart
}
