import type { Image, ProductVariantsRaw } from '@lib/types'
import type { ImageRaw, ImagesRaw } from '@lib/types/raw'

export const normalizeImages = (
	{ edges }: ImagesRaw,
	title?: string,
	variants?: ProductVariantsRaw['nodes'],
	brand?: string
): Image[] =>
	edges?.map(({ node: image }, index) =>
		normalizeImage(
			image,
			`${brand ?? ''} ${title ?? ''} ${imageAltTextFromVariant(variants, image.id)} ${
				index + 1
			}`.trim()
		)
	)

export const imageAltTextFromVariant = (
	variants: ProductVariantsRaw['nodes'] | undefined,
	imageId: string
): string => {
	if (!variants) return ''

	const v = variants?.find((variant: any) => variant?.image?.id === imageId)
	return v?.title || ''
}

/**
 * Normalize Shopify images
 */
export const normalizeImage = (image: Image | ImageRaw, altText?: string): Image => ({
	id: image.id,
	url: image.url,
	altText: altText || image.altText,
	width: image.width,
	height: image.height
})

/**
 * Normalize price.
 *
 * @param param0 string amount to normalize
 * @returns amount as a string with 2 decimal places
 */
export const normalizePrice = (amount: string): string => parseFloat(amount).toFixed(2)
