import type { Cart, CartItem } from '@lib/types/cart'

// FIXME: types, remove unused fields
export function normalizeCart(cart: any): Cart {
	Object.entries(cart.estimatedCost).forEach(([, value]: [any, any]) => {
		// If this field is a currency, make it have two decimal places
		if (value && value.amount && value.currencyCode) {
			// eslint-disable-next-line no-param-reassign
			value.amount = Number(value.amount).toFixed(2)
		}
	})

	return {
		id: cart.id,
		estimatedCost: cart.estimatedCost,
		checkoutUrl: cart.checkoutUrl,
		lines: cart.lines,
		discountCodes: cart.discountCodes
	}
}

// FIXME: types, remove unused fields
export function normalizeCartItem(item: any): CartItem {
	// eslint-disable-next-line no-param-reassign
	item.node.estimatedCost.subtotalAmount.amount = Number(
		item.node.estimatedCost.subtotalAmount.amount
	).toFixed(2)

	return {
		...item.node,
		image: item.node.merchandise.image
	}
}
