import { signOut } from 'next-auth/react'

import type { AxiosResponse } from 'axios'

export function getError(errors: any[] | null, status: number) {
	const err = errors ?? [{ message: 'Failed to fetch Shopify API' }]
	return { err, status }
}

export async function getAsyncError(res: AxiosResponse) {
	return getError(res.data.errors, res.status)
}

const handleFetchResponse = async (res: AxiosResponse) => {
	// refer to Shopify documentation: https://shopify.dev/api/storefront#status_and_error_codes
	if (res.status === 200) {
		const { data, errors, extensions } = res.data

		if (errors && errors.length) {
			throw getError(errors, res.status)
		}
		if (extensions) {
			console.log('Shopify Admin API cost:', extensions?.cost)
			if (extensions?.cost?.currentlyAvailable && extensions?.cost?.currentlyAvailable < 1000)
				console.warn('Shopify Admin API available cost:', extensions?.cost?.currentlyAvailable)
		}
		return data
	}

	throw getAsyncError(res)
}

export default handleFetchResponse

export const handleSubscriptionFetchResponse = async (res: AxiosResponse) => {
	// refer to Shopify documentation: https://shopify.dev/api/storefront#status_and_error_codes
	if (res.status === 200) {
		const { data, errors } = res.data

		if (errors && errors.length) {
			const notAuthenticatedErrors = errors.filter(
				({ message }: { message?: string }) =>
					message && message.toLowerCase() === 'not authenticated'
			)

			if (notAuthenticatedErrors.length > 0) {
				signOut({ callbackUrl: '/login/?reason=expired' })
			}

			throw getError(errors, res.status)
		}

		return data
	}

	throw getAsyncError(res)
}
