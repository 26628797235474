import fetcher from '@lib/fetcher'
import customerUpdateMutation from '@lib/mutations/customer-update'
import type { CustomerUserErrors, Password } from '@lib/types/customer'

// eslint-disable-next-line import/prefer-default-export
export async function updatePassword(
	customerAccessToken: string,
	password: Password
): Promise<true | CustomerUserErrors> {
	const updateOrError = await fetcher({
		variables: { customerAccessToken, input: { password } },
		query: customerUpdateMutation
	})

	if (updateOrError?.customerUpdate?.customerUserErrors?.length) {
		const { customerUserErrors: errors } = updateOrError.customerUpdate

		return errors
	}

	return true
}
