import getCartId from '@lib/cart/get-cart-id'
import fetcher from '@lib/fetcher'
import cartLinesRemoveMutation from '@lib/mutations/cart-lines-remove'
import type { ProductVariant } from '@lib/types/product'

import { ShopifyWebStorefrontClient } from '@components/cart/OnwardStorefrontClient'

export type RemoveItemParams = {
	id: ProductVariant['id']
}

/**
 * Remove cart items using Shopify's cart API
 *
 * @see https://shopify.dev/api/examples/cart
 */
export default async function removeItem({ id }: RemoveItemParams) {
	const lineIds = [id]
	const cartId = getCartId()

	if (!cartId) {
		return null
	}

	const { cartLinesRemove } = await fetcher({
		query: cartLinesRemoveMutation,
		variables: {
			cartId,
			lineIds
		}
	})

	if (window.onwardApp && window.onwardClient) {
		window.onwardApp.cartChanged(ShopifyWebStorefrontClient.parseCart(cartLinesRemove.cart))
	}

	return cartLinesRemove
}
