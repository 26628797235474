import { useMemo, useState } from 'react'

import CartTextNodes from '@data/i18n/cart.json'

import useCart from '@lib/cart/use-cart'
import { lang } from '@lib/constants'

export default function CartDiscoundCodeField() {
	const { items, appliedDiscountCodes, addDiscountCode, removeDiscountCode } = useCart() || {}

	const [inputCode, setInputCode] = useState('')
	const [invalidCodeError, setInvalidCodeError] = useState('')
	const [codeStatus, setCodeStatus] = useState<'idle' | 'loading'>('idle')

	const appliedDiscountsToItems = useMemo(
		() =>
			!!items && items.length > 0
				? !!items.find((item) => item.discountAllocations.length > 0)
				: false,
		[items]
	)

	const applyDiscountCode = async (codeToApply: string) => {
		if (!codeToApply) {
			return
		}
		setCodeStatus('loading')

		const { applied, errors } = await addDiscountCode(codeToApply)

		if (applied) {
			setInputCode('')
		} else if (!errors || errors.length === 0) {
			setInvalidCodeError(CartTextNodes.enterValidDiscoundCode)
		}
		setCodeStatus('idle')
	}

	return !!items && items.length > 0 ? (
		<form
			className="mb-4 pb-4 border-b border-gray-200"
			onSubmit={(e) => {
				e.preventDefault()
				applyDiscountCode(inputCode)
			}}
		>
			{!!invalidCodeError && (
				<p className="mb-1 text-sm font-medium text-bbx-spark">{invalidCodeError}</p>
			)}

			<div className="flex gap-3 flex-col md:flex-row items-center justify-between">
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<label htmlFor="discountCode" className="sr-only">
					Discount Code
				</label>
				<input
					className={`input md:flex-grow w-full leading-none ${
						appliedDiscountsToItems && appliedDiscountCodes.length === 0 ? 'bg-gray-200' : ''
					}`}
					placeholder={CartTextNodes.discountCodeInputPlaceholder}
					type="text"
					id="discountCode"
					name="discountCode"
					value={inputCode}
					onChange={({ target }) => {
						setInvalidCodeError('')
						setInputCode(target.value.trim())
					}}
					disabled={appliedDiscountsToItems && appliedDiscountCodes.length === 0}
					style={{ fontSize: lang === 'es-es' && !inputCode ? '12px' : undefined }}
				/>
				<button
					type="submit"
					className={`button button-primary max-md:w-full ${
						codeStatus === 'loading' ? 'animate-pulse' : ''
					}`}
					disabled={!inputCode || codeStatus === 'loading'}
				>
					{CartTextNodes.applyCTA}
				</button>
			</div>

			{appliedDiscountsToItems && (
				<div className={`flex mt-2 text-sm ${codeStatus === 'loading' ? 'opacity-50' : ''}`}>
					{appliedDiscountCodes.length === 0 ? (
						<span className=" font-medium text-bbx-spark">{CartTextNodes.appliedDiscount}</span>
					) : (
						appliedDiscountCodes.map((code) => (
							<div key={code} className="flex items-center pl-3 pt-1 rounded-sm bg-gray-200">
								{code}
								<button
									type="button"
									disabled={codeStatus === 'loading'}
									aria-label="Remove code"
									className="px-2 pt-1 pb-2 ml-1 disabled:opacity-50"
									onClick={async () => {
										setCodeStatus('loading')
										await removeDiscountCode(code)
										setCodeStatus('idle')
									}}
								>
									<svg className="w-4 h-4 fill-current" role="button" viewBox="0 0 20 20">
										<path
											d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
											clipRule="evenodd"
											fillRule="evenodd"
										/>
									</svg>
								</button>
							</div>
						))
					)}
				</div>
			)}
			{lang === 'en-us' && <div id="onward-container" className="mt-4" />}
		</form>
	) : null
}
