import useToast from '@context/useToast'
import { useForm } from 'react-hook-form'

import CommonTextNodes from '@data/i18n/common.json'

import { isUs } from '@lib/utils/lang'

type FormInputs = {
	newsletterEmail: string
	acceptsMarketing: boolean
}

export default function NewsletterSignUp() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid }
	} = useForm<FormInputs>({
		mode: 'onChange',
		defaultValues: {
			acceptsMarketing: isUs()
		}
	})

	const toast = useToast()

	const onSubmit = async ({ newsletterEmail, acceptsMarketing }: FormInputs) => {
		if (process.env.NEXT_PUBLIC_LANG !== 'en-US' && !acceptsMarketing) return;

		const countryCode = process.env.NEXT_PUBLIC_LANG === 'en-US' ? 'US' : 'GB';

		if (newsletterEmail) {
			try {
				const response = await fetch('https://sst01.birchbox.com/braze/braze_email_sms_dc.php', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: newsletterEmail,
						subscribeType: 'email',
						subscriptionGroupId: '056b9729-188b-4068-b507-a4def52fc533',
						countryCode,
						eventName: 'website_newsletter_signup',
					})
				});

				if (!response.ok) {
					throw new Error(`Request failed with status ${response.status}`);
				}

				toast('Success!', "You're signed up to receive the latest deals, news, and more!");
				reset();
			} catch (error) {
				toast('Something went wrong', 'Please wait a few minutes, and try signing up again.')
			}
		}
	}

	return (
		<>
			<h2 className="text-lg font-medium max-sm:max-w-[200px]">
				{CommonTextNodes.footer.enterEmailText}
			</h2>

			{/* Newsletter sign-up */}
			<form
				className="flex flex-col mb-4 max-lg:gap-y-2.5 lg:gap-x-3"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex max-lg:flex-col max-lg:gap-y-2.5 lg:gap-x-3">
					<input
						{...register('newsletterEmail')}
						id="newsletterEmail"
						required
						type="email"
						placeholder={CommonTextNodes.footer.placeholder}
						className="w-full input"
					/>
					<button
						type="submit"
						className="button button-primary shrink-0 max-lg:w-full"
						disabled={!isValid}
					>
						{CommonTextNodes.footer.cta}
					</button>
				</div>

				{/* Agree to advertising checkbox */}
				{/* <label
					htmlFor="acceptsMarketing"
					className="flex items-start mt-5 font-light text-gray-500 gap-x-5 lg:mt-7 lg:w-9/12"
				>
					<input
						type="checkbox"
						id="acceptsMarketing"
						className="mt-1 shrink-0 accent-bbx-style"
						required
						{...register('acceptsMarketing', { required: true })}
					/>

					<span className="text-sm">
						{isUs() && CommonTextNodes?.footer?.privacy
							? CommonTextNodes.footer.privacy
							: CommonTextNodes?.footer?.privacy && (
									<>
										{CommonTextNodes.footer.privacy[0]}{' '}
										<Link href={CommonTextNodes.privacyHref}>
											<a className="underline">{CommonTextNodes.footer.privacyLink}</a>
										</Link>{' '}
										{CommonTextNodes.footer.privacy[1]}
										<p>
											{CommonTextNodes.footer.privacy[2]}{' '}
											<Link href={CommonTextNodes.privacyHref}>
												<a className="underline">{CommonTextNodes.footer.privacyLink}.</a>
											</Link>{' '}
										</p>
									</>
							  )}
					</span>
				</label> */}
			</form>
		</>
	)
}
