import { adminFetcher } from '@lib/fetcher'
import customerGenerateAccountActivationUrlMutation from '@lib/mutations/customer-generate-account-activation-url'

interface CustomerAccountActivationUrlErrorType {
	field: string[]
	message: string
}

interface CustomerGenerateAccountActivationUrlType {
	accountActivationUrl: string
	userErrors: CustomerAccountActivationUrlErrorType[]
}

export class ErrorCustomerAccountActivationUrl extends Error {
	customerId: string

	errorData: CustomerAccountActivationUrlErrorType[]

	constructor(
		customerId: string,
		errorData: CustomerAccountActivationUrlErrorType[],
		message?: string
	) {
		super(message ?? `Failed to generate customer account activation URL (ID: ${customerId})`)
		this.customerId = customerId
		this.errorData = errorData
	}
}

export const isErrorCustomerAccountActivationUrl = (
	error: any
): error is ErrorCustomerAccountActivationUrl => error.errorData && error.customerId

export async function getCustomerAccountActivationUrl(customerId: string) {
	const { customerGenerateAccountActivationUrl } = await adminFetcher({
		variables: { customerId: `gid://shopify/Customer/${customerId}` },
		query: customerGenerateAccountActivationUrlMutation
	})

	const { accountActivationUrl, userErrors }: CustomerGenerateAccountActivationUrlType =
		customerGenerateAccountActivationUrl

	if (userErrors && userErrors.length > 0) {
		throw new ErrorCustomerAccountActivationUrl(customerId, userErrors)
	}

	if (!accountActivationUrl) {
		throw new Error('Invalid request response')
	}

	return accountActivationUrl
}

export default getCustomerAccountActivationUrl
