import { useState } from 'react'

import Cookies from 'js-cookie'
import type { KeyedMutator } from 'swr'
import useSWR from 'swr'

import type { CookieSettings } from '@lib/types'

const COOKIES_KEY = 'birchbox-cookies-settings' as const

type UseCookiesSettingsReturn = {
	cookieSettings: undefined | CookieSettings
	cookiesKey: typeof COOKIES_KEY
	revalidate: KeyedMutator<CookieSettings | undefined>
	cookiesLoaded: boolean
}

export default function useCookiesSettings(): UseCookiesSettingsReturn {
	const { data, mutate } = useSWR(COOKIES_KEY, getCookies)

	const [cookiesLoaded, setCookiesLoaded] = useState(false)

	function getCookies() {
		if (!cookiesLoaded) {
			setCookiesLoaded(true)
		}

		const cookies = Cookies.get(COOKIES_KEY)

		if (cookies) {
			try {
				return JSON.parse(cookies)
			} catch (error) {
				// malformed value
			}
		}

		return cookies
	}

	return { cookieSettings: data, cookiesKey: COOKIES_KEY, revalidate: mutate, cookiesLoaded }
}
