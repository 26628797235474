import { Fragment, useEffect, useState } from 'react'

import {
	useAnimateInReady,
	useHandleSurveySubmissions,
	useSetAnimationReady,
	useSetNextButtonDisabled,
	useSurveyState
} from '@context/subscription-box/useSurvey'
import { Transition } from '@headlessui/react'

import type { SurveyQuestion } from '@lib/types/subscription-box'

type MultiSelectParams = {
	surveyQuestion: SurveyQuestion
	isRequired?: boolean
	index: number
}
export default function MultiSelect({ surveyQuestion, isRequired, index }: MultiSelectParams) {
	const { currentIndex, translateDirection } = useSurveyState()
	const animateInReady = useAnimateInReady()
	const setAnimateInReady = useSetAnimationReady()
	const handleSurveySubmissions = useHandleSurveySubmissions()
	const setNextButtonDisabled = useSetNextButtonDisabled()

	const [answerOptions, setAnswerOptions] = useState(
		surveyQuestion?.surveyAnswerOptions?.map(({ optionValue, optionLabel, id }) => ({
			optionValue,
			optionLabel,
			active: false,
			id
		}))
	)

	// Handle state change on option click
	const handleValueChange = (optionValue: string) => {
		setAnswerOptions((prev) =>
			prev.map(({ optionValue: prevOptionValue, active: prevActive, ...rest }) => ({
				optionValue: prevOptionValue,
				active: optionValue === prevOptionValue ? !prevActive : prevActive,
				...rest
			}))
		)
	}

	// Handle updating survey submission questions when answerOptions change
	useEffect(() => {
		if (index === currentIndex) {
			handleSurveySubmissions(surveyQuestion, answerOptions)
		}
	}, [answerOptions, index, currentIndex])

	// Handle disabling next button if threshold isn't met
	useEffect(() => {
		if (index === currentIndex) {
			const selectedOptions = answerOptions?.filter(({ active }) => active)

			// If question has a specified minimum number that user needs to select
			if (surveyQuestion?.displayOptions?.minNumSelected) {
				if (selectedOptions?.length >= surveyQuestion?.displayOptions?.minNumSelected) {
					return setNextButtonDisabled(false)
				}

				return setNextButtonDisabled(true)
			}

			// If none are selected, and isRequired, then disable the button
			if (selectedOptions?.length === 0 && isRequired) {
				return setNextButtonDisabled(true)
			}

			// Default to not disabling button
			return setNextButtonDisabled(false)
		}

		return undefined
	}, [answerOptions, surveyQuestion, index, currentIndex])

	return (
		<Transition
			show={index === currentIndex && animateInReady}
			as={Fragment}
			afterLeave={() => setAnimateInReady(true)}
			enter="transition ease-in-out duration-[400ms] delay-[400ms]"
			enterFrom={`opacity-0 ${
				translateDirection === 'forwards' ? 'translate-x-1/3' : '-translate-x-1/3'
			}`}
			enterTo="opacity-100 translate-x-0"
			leave="transition ease-in-out duration-[400ms]"
			leaveFrom="opacity-100 translate-x-0"
			leaveTo={`opacity-0 ${
				translateDirection === 'forwards' ? '-translate-x-1/3' : 'translate-x-1/3'
			}`}
		>
			<div className="flex flex-col">
				{/* Survey question body */}
				{surveyQuestion?.body && (
					<h3 className="text-lg font-medium text-center md:text-xl">{surveyQuestion.body}</h3>
				)}

				{/* Modal description */}
				{surveyQuestion?.displayOptions?.minNumSelected && (
					<p className="mt-4 font-medium text-center md:mt-16 text-gray-500 max-md:text-sm">
						Choose at least {surveyQuestion?.displayOptions?.minNumSelected}
					</p>
				)}

				{Array.isArray(surveyQuestion?.surveyAnswerOptions) && (
					<ul className="max-w-[800px] flex flex-wrap justify-center self-center sm:justify-items-center mt-8 md:mt-12">
						{answerOptions?.map(({ optionLabel, optionValue, active, id }) =>
							optionLabel ? (
								<li className="m-2 md:m-3 max-sm:w-full" key={id}>
									<button
										type="button"
										className={`px-4 py-3 md:py-2 font-medium border rounded-sm transition-colors duration-200 ease-in-out max-md:text-xs w-full ${
											active
												? 'border-bbx-spark bg-bbx-pearl-light text-bbx-spark'
												: 'border-gray-200 bg-gray-100 text-gray-500 font-medium'
										}`}
										// Flip active status on click
										onClick={() => handleValueChange(optionValue)}
									>
										{optionLabel}
									</button>
								</li>
							) : null
						)}
					</ul>
				)}
			</div>
		</Transition>
	)
}
