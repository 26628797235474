import Cookies from 'js-cookie'

import getCartId from '@lib/cart/get-cart-id'
import { SHOPIFY_CART_ID_COOKIE } from '@lib/constants'

/**
 * Remove current checkout from local cookie
 */
export default async function destroyCart() {
	const cartId = getCartId()

	if (cartId) {
		Cookies.remove(SHOPIFY_CART_ID_COOKIE)
	}
}
