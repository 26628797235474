import { cartDetailsFragment } from '../queries/get-cart-query'

/**
 * @see https://shopify.dev/api/storefront/2022-04/mutations/cartLinesAdd
 */
const cartLinesAddMutation = /* GraphQL */ `
	mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			userErrors {
				code
				field
				message
			}
			cart {
				...cartDetails
			}
		}
	}

	${cartDetailsFragment}
`
export default cartLinesAddMutation
