const customerUpdateMutation = /* GraphQL */ `
	mutation customerUpdate($customerAccessToken: String!, $input: CustomerUpdateInput!) {
		customerUpdate(customerAccessToken: $customerAccessToken, customer: $input) {
			customer {
				id
			}
			customerUserErrors {
				message
				field
				code
			}
		}
	}
`

export default customerUpdateMutation
