import { createHash } from 'crypto'

/* eslint-disable import/prefer-default-export */
export async function sha256(message: string) {
	// encode as UTF-8
	const msgBuffer = new TextEncoder().encode(message)

	// hash the message
	const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

	// convert ArrayBuffer to Array
	const hashArray = Array.from(new Uint8Array(hashBuffer))

	// convert bytes to hex string
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
	return hashHex
}

export function sha256Node(message: string) {
	const hash = createHash('sha256')
	hash.update(message)
	return hash.digest('hex')
}
