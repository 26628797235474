import type { ProductPrice, WeightUnit } from '../types'
import type { SmileIOPointsTier } from '../types/points'
import { freeGroundShippingMins, freeTwoDayShippingMins } from './shipping'

// Localization
export const lang: 'en-us' | 'en-gb' | 'es-es' = (
	process.env.NEXT_PUBLIC_LANG || 'en-US'
).toLowerCase() as 'en-us' | 'en-gb' | 'es-es'

export const SHOPIFY_CART_ID_COOKIE = 'shopify_cartId'
export const SHOPIFY_CHECKOUT_URL_COOKIE = 'shopify_checkoutUrl'
export const SHOPIFY_CUSTOMER_TOKEN_COOKIE = 'shopify_customerToken'

export const STORE_DOMAIN = process.env.NEXT_PUBLIC_STORE_DOMAIN
export const SHOPIFY_COOKIE_EXPIRE = 30

export const API_STOREFRONT_VERSION = '2024-04'
export const API_ADMIN_VERSION = '2024-04'
export const API_URL = `https://${STORE_DOMAIN}/api/${API_STOREFRONT_VERSION}/graphql.json`
export const ADMIN_API_URL = `https://${STORE_DOMAIN}/admin/api/${API_ADMIN_VERSION}/graphql.json`
export const ADMIN_API_TOKEN = process.env.SHOPIFY_ADMIN_ACCESS_TOKEN
export const API_TOKEN = process.env.NEXT_PUBLIC_STOREFRONT_API_ACCESS_TOKEN

export const CURRENCY_MAP = new Map([
	['USD', '$'],
	['EUR', '€'],
	['ARS', '$'],
	['GBP', '£']
])

export const SHOPIFY_IMAGE_CDN_BASE = 'https://cdn.shopify.com/s/files'

export const MAX_SEARCH_RESULTS_IN_DROPDOWN = 6

// The url is coming from Prismic
export const CUSTOMIZABLE_BOX_HANDLE = lang === 'en-gb' ? 'subscribe' : 'membership'

// Smile.io configuration
export const SMILE_IO_API_ENDPOINT = 'https://api.smile.io/'
// Should come straight from smile.io. Is not the same as the amount of points received per dollar spent.
export const SMILE_IO_POINTS_PER_DOLLAR = 500

// Smile.io APIs for getting tiers is not public
// Contigent on the configuration in Smile.IO
// ID is used for checking equality
export const SMILE_IO_HOLDER_TIER: SmileIOPointsTier = {
	name: 'Account Holder',
	minPoints: 0,
	id: 'holder'
}

export const SMILE_IO_ACE_TIER: SmileIOPointsTier = {
	name: 'Birchbox Ace',
	minPoints: 3000,
	id: 'ace',
	image: '/images/bbx-gold-diamond.svg'
}

export const SMILE_IO_TIERS = [SMILE_IO_HOLDER_TIER, SMILE_IO_ACE_TIER] as SmileIOPointsTier[]

export const WEIGHT_UNIT_MAP: Record<WeightUnit, string> = {
	GRAMS: 'g',
	KILOGRAMS: 'kg',
	OUNCES: 'oz',
	POUNDS: 'lb'
} as const

export const FULFILLMENT_DISPLAY_STATUS = {
	ATTEMPTED_DELIVERY: 'Attempted delivery',
	CANCELED: 'Canceled',
	CONFIRMED: 'Confirmed',
	DELIVERED: 'Delivered',
	FAILURE: 'Failure',
	FULFILLED: 'Shipped',
	IN_TRANSIT: 'In transit',
	LABEL_PRINTED: 'Label printed',
	LABEL_PURCHASED: 'Label purchased',
	LABEL_VOIDED: 'Label voided',
	MARKED_AS_FULFILLED: 'Marked as fulfilled',
	NOT_DELIVERED: 'Not delivered',
	OUT_FOR_DELIVERY: 'Out for delivery',
	READY_FOR_PICKUP: 'Ready for pickup',
	PICKED_UP: 'Picked up',
	SUBMITTED: 'Submitted'
} as const

export const ORDER_FULFILLMENT_STATUS = {
	UNFULFILLED: 'Order Placed',
	PARTIALLY_FULFILLED: 'Partially fulfilled',
	FULFILLED: 'Shipped',
	RESTOCKED: 'Restocked',
	PENDING_FULFILLMENT: 'Order Placed',
	OPEN: 'Open',
	IN_PROGRESS: 'In progress',
	ON_HOLD: 'On hold',
	SCHEDULED: 'Scheduled'
} as const

export const MULTIPLE_BOX_ERROR_TEXT =
	'Only one subscription box is allowed per account. Create a new account to add another box.'

export const dateMapping = {
	1: '1st',
	2: '2nd',
	3: '3rd',
	4: '4th',
	5: '5th',
	6: '6th',
	7: '7th',
	8: '8th',
	9: '9th',
	10: '10th',
	11: '11th',
	12: '12th',
	13: '13th',
	14: '14th',
	15: '15th',
	16: '16th',
	17: '17th',
	18: '18th',
	19: '19th',
	20: '20th',
	21: '21st',
	22: '22nd',
	23: '23rd',
	24: '24th',
	25: '25th',
	26: '26th',
	27: '27th',
	28: '28th',
	29: '29th',
	30: '30th',
	31: '31st'
}

// Note: app assumes ground shipping is cheaper than two day shipping
export const FREE_GROUND_SHIPPING_MINIMUM_AMOUNT: ProductPrice = freeGroundShippingMins.get(lang)!

export const FREE_TWO_DAY_SHIPPING_MINIMUM_AMOUNT = freeTwoDayShippingMins.get(lang)!

// i18n
export const searchUrl = (lang === 'es-es' ? '/buscar' : '/search') as '/search' | '/buscar'

export const colUrl = (lang === 'es-es' ? '/categorias' : '/collections') as
	| '/categorias'
	| '/collections'

export const prodUrl = (lang === 'es-es' ? '/producto' : '/products') as '/products' | '/producto'
export const collectionsUrl = lang === 'es-es' ? '/categorias' : '/collections'

export const TypeUrlMap = new Map([
	['page', '/'],
	['article', '/magazine/article/'],
	['product', prodUrl] // no trailing slash, since shopify adds it to the handle
])

// Used in the cards
export const SUBSCRIPTION_BOX_BASE_PRICE = 15

export const ONWARD_WIDGET_URL = process.env.NEXT_PUBLIC_ONWARD_WIDGET_URL

export const RMC_MINIMUM_HOURS_TO_CUSTOMIZE = 72

export const RMC_MAXIMUM_HOURS_TO_CUSTOMIZE = 24 * 10 // 10 days
