type DiscountTiersKey = 'es-es' | 'en-gb'
type LoyaltyTierTag =
	| 'VIPLOYALTY'
	| '5OFFLOYALTY'
	| '10OFFLOYALTY'
	| '15OFFLOYALTY'
	| '18OFFLOYALTY'
	| '20OFFLOYALTY'
type DiscountTiersValue = {
	month: number
	discount: number
	tag?: LoyaltyTierTag // The customer tag assigned through the Shopify admin panel
	message?: string // The discount message set through the Shopify (editor) script: "Loyalty tier discount"
}

const discountTiers: Record<DiscountTiersKey, DiscountTiersValue[]> = {
	'es-es': [
		{
			month: 0,
			discount: 5,
			tag: '5OFFLOYALTY',
			message: '5% de descuento en todos los productos'
		},
		{
			month: 2,
			discount: 10,
			tag: '10OFFLOYALTY',
			message: '10% de descuento en todos los productos'
		},
		{
			month: 4,
			discount: 15,
			tag: '15OFFLOYALTY',
			message: '15% de descuento en todos los productos'
		},
		{
			month: 8,
			discount: 18,
			tag: '18OFFLOYALTY',
			message: '18% de descuento en todos los productos'
		},
		{
			month: 12,
			discount: 20,
			tag: '20OFFLOYALTY',
			message: '20% de descuento en todos los productos'
		}
	],
	'en-gb': [
		{
			month: 0,
			discount: 0
		},
		{
			month: 6,
			discount: 15,
			tag: 'VIPLOYALTY',
			message: '15% discount for all products'
		}
	]
}

export const productDiscountTierExcludeTag = 'loyal-excluded'

export default discountTiers
