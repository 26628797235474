import { Fragment, useEffect } from 'react'

import Image from 'next/image'

import {
	useAnimateInReady,
	useHandleSurveySubmissions,
	useSetAnimationReady,
	useSetNextButtonDisabled,
	useSurveyState,
	useSurveySubmissions
} from '@context/subscription-box/useSurvey'
import { Transition } from '@headlessui/react'

import type { SurveyQuestion } from '@lib/types/subscription-box'

type RadioSelectParams = {
	surveyQuestion: SurveyQuestion
	isRequired?: boolean
	index: number
}
export default function RadioSelect({ surveyQuestion, isRequired, index }: RadioSelectParams) {
	const { currentIndex, translateDirection } = useSurveyState()
	const surveySubmissions = useSurveySubmissions()
	const handleSurveySubmissions = useHandleSurveySubmissions()
	const animateInReady = useAnimateInReady()
	const setAnimateInReady = useSetAnimationReady()
	const setNextButtonDisabled = useSetNextButtonDisabled()

	// Set survey submissions on radio value change
	const handleValueChange = (optionValue: string, id: number) => {
		if (optionValue) {
			handleSurveySubmissions(surveyQuestion, {
				optionValue,
				id
			})
		}
	}

	// Handle next button disabled
	useEffect(() => {
		if (index === currentIndex) {
			if (isRequired && !surveySubmissions[currentIndex]?.response) {
				setNextButtonDisabled(true)
			} else {
				setNextButtonDisabled(false)
			}
		}
	}, [surveySubmissions, currentIndex, isRequired, index])

	return (
		<Transition
			show={index === currentIndex && animateInReady}
			as={Fragment}
			afterLeave={() => setAnimateInReady(true)}
			enter="transition ease-in-out duration-[400ms] delay-[400ms]"
			enterFrom={`opacity-0 ${
				translateDirection === 'forwards' ? 'translate-x-1/3' : '-translate-x-1/3'
			}`}
			enterTo="opacity-100 translate-x-0"
			leave="transition ease-in-out duration-[400ms]"
			leaveFrom="opacity-100 translate-x-0"
			leaveTo={`opacity-0 ${
				translateDirection === 'forwards' ? '-translate-x-1/3' : 'translate-x-1/3'
			}`}
		>
			<div>
				{/* Survey question body */}
				{surveyQuestion?.body && (
					<h3 className="text-lg font-medium text-center md:text-xl">{surveyQuestion.body}</h3>
				)}

				{Array.isArray(surveyQuestion?.surveyAnswerOptions) && (
					<ul className="flex flex-wrap self-center justify-center mt-8 mb-2 md:mt-10">
						{surveyQuestion.surveyAnswerOptions.map(
							({ optionLabel, optionValue, optionImage, id }) => {
								const thisOptionIsSelected =
									surveySubmissions?.[currentIndex]?.response[0] === optionValue

								return (
									<li className={`
										${optionImage
												? 'm-4 md:m-5'
												: 'm-1.5 md:m-3 max-sm:w-full'
										}`} 
										key={id}>
										{!optionImage ? (
											<button 
												type="button" 
												className={`gap-8 px-4 py-3 md:py-2 font-medium border rounded-sm transition-colors duration-200 ease-in-out max-md:text-xs max-sm:w-full ${
												thisOptionIsSelected
													? 'border-bbx-spark bg-bbx-pearl-light text-bbx-spark'
													: 'border-gray-200 bg-gray-100 text-gray-500 font-medium'
												}`}
												onClick={() => handleValueChange(optionValue, id)}
											>
												{optionLabel}
											</button>
										) : (
											<button
												type="button"
												className="flex flex-col items-center gap-6 max-w-[100px] md:max-w-[180px]"
												onClick={() => handleValueChange(optionValue, id)}
											>
												<Image
													className="rounded-full"
													src={optionImage}
													alt={`Survey question image: ${optionLabel}`}
													width={96}
													height={96}
												/>
												<div className="relative inline-block px-0.5 md:px-2.5 pb-1 md:pb-2 transition-colors duration-300 ease-in-out">
												{/* Option label */}
												{optionLabel && (
													<p
													className={`font-medium text-center ${
														thisOptionIsSelected ? '' : 'text-gray-450'
													}`}
													>
													{optionLabel}
													</p>
												)}
												{/* Border bottom when active */}
												<div
													aria-hidden="true"
													className={`absolute inset-x-0 bottom-0 h-0.5 bg-bbx-spark rounded-full transition-opacity duration-75 ease-in-out ${
													thisOptionIsSelected ? 'opacity-100' : 'opacity-0'
													}`}
												/>
												</div>
											</button>
										)}

									</li>
								)
							}
						)}
					</ul>
				)}
			</div>
		</Transition>
	)
}
