export type UpdateCartAttributesInput = {
	cartId: string
	attributes: {
		key: string
		value: string
	}[]
}

const updateCartAttributes = /* GraphQL */ `
	mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
		cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
			cart {
				id
			}
			userErrors {
				field
				message
			}
		}
	}
`
export default updateCartAttributes
