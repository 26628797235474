import { useEffect, useState, useContext, useMemo } from 'react'
import type { ReactNode } from 'react'

import { useSession } from 'next-auth/react'

import NavHeightContext from '@context/NavHeightContext'

import useBrazeIntegration from '@context/useBrazeIntegration'

import { Footer, Nav } from '@components/global'
import CookieSettings from '@components/global/CookieSettings'
import Toasts from '@components/global/ToastView'

type MainLayoutProps = {
	children: ReactNode
}

export default function MainLayout({ children }: MainLayoutProps) {
	// Open braze session when user is detected
	const { data: session } = useSession()
	const [brazeSessionOpened, setBrazeSessionOpened] = useState(false)
	const { brazeFunction } = useBrazeIntegration()

	const [navHeight, setNavHeight] = useState(0)
	const heightContextValue = useMemo(() => ({ navHeight, setNavHeight }), [navHeight, setNavHeight])

	useEffect(() => {
		if (!brazeSessionOpened) {
			;(async () => {
				const braze = await brazeFunction()

				if (braze) {
					
					braze.openSession()
					setBrazeSessionOpened(true)
				}
			})()
		}
	}, [brazeSessionOpened, session, brazeFunction])

	return (
		<NavHeightContext.Provider value={heightContextValue}>
			{/* Nav */}
			<Nav />

			{/* Main Content */}
			<main style={{ paddingTop: `${navHeight}px` }}>{children}</main>

			{/* Footer */}
			<Footer />

			{/* Toasts */}
			<Toasts />

			{/* Cookie Settings */}
			<CookieSettings />
		</NavHeightContext.Provider>
	)
}
