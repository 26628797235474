import createCart from '@lib/cart/create-cart'
import getCartId from '@lib/cart/get-cart-id'
import fetcher from '@lib/fetcher'
import cartLinesAddMutation from '@lib/mutations/cart-lines-add'

import type { AddItemParams } from './add-item'

/**
 * Add cart items using Shopify's cart API
 *
 * @see https://shopify.dev/api/examples/cart
 */
export default async function addItems(items: AddItemParams[]) {
	// { variantId, quantity, sellingPlanId }
	const lineItems = items.map(({ variantId, quantity, sellingPlanId }) => ({
		merchandiseId: variantId,
		quantity,
		...(sellingPlanId ? { sellingPlanId } : {})
	}))
	const cartId = getCartId()

	if (!cartId) {
		return createCart(lineItems)
	}

	const { cartLinesAdd } = await fetcher({
		query: cartLinesAddMutation,
		variables: {
			cartId,
			lines: lineItems
		}
	})

	return cartLinesAdd
}
