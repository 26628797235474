// @ts-ignore
import SubscriptionBoxData from '@data/subscription-box.json'

const ALL_CUSTOMIZABLE_BOX_VARIANT_IDS = (SubscriptionBoxData as any[]).map(
	(item) => item.variantId
)
const ALL_CUSTOMIZABLE_BOX_PRODUCT_IDS = (SubscriptionBoxData as any[]).map(
	(item) => item.productId
)

export const isCustomizableBoxVariant = (variantId: string | undefined) =>
	variantId && ALL_CUSTOMIZABLE_BOX_VARIANT_IDS.includes(variantId)

export const isCustomizableBoxProduct = (productId: string) =>
	productId && ALL_CUSTOMIZABLE_BOX_PRODUCT_IDS.includes(productId)
