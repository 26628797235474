import { useRef } from 'react'

import { usePreviousSubscriptionBoxStep } from '@context/subscription-box/useSubscriptionBoxState'
import {
	useHandleSkipSubmission,
	useNextButtonDisabled,
	useNextQuestion,
	usePreviousQuestion,
	useSetAnimationReady,
	useSurvey,
	useSurveyState
} from '@context/subscription-box/useSurvey'

import commonTextNodes from '@data/i18n/common.json'

import MultiSelect from './MultiSelect'
import RadioSelect from './RadioSelect'
import RangeSlider from './RangeSlider'

type SurveyParams = {
	callBack?: (() => void) | null
}
export default function Survey({ callBack = null }: SurveyParams) {
	// Survey questions
	const { surveyQuestions } = useSurvey() || {}

	const { surveyProgress, currentIndex } = useSurveyState()
	const nextQuestion = useNextQuestion()
	const previousQuestion = usePreviousQuestion()
	const setAnimateInReady = useSetAnimationReady()
	const handleSkipSubmission = useHandleSkipSubmission()
	const nextButtonDisabled = useNextButtonDisabled()

	// Subscription box flow state
	const previousStep = usePreviousSubscriptionBoxStep()

	const progressBarRef = useRef<HTMLDivElement>(null)

	const scrollToTop = () => progressBarRef?.current?.scrollIntoView({ behavior: 'smooth' })

	const handleNext = async () => {
		// If at last step run callback function

		if (
			surveyQuestions?.[currentIndex]?.id === surveyQuestions?.[surveyQuestions.length - 1]?.id &&
			!!callBack
		) {
			callBack()
		} else {
			scrollToTop()
			setAnimateInReady(false)
			nextQuestion()
		}
	}

	return (
		<div className="flex justify-center w-full px-4">
			<div className="relative bg-white rounded shadow-lg max-w-[1200px] w-full overflow-hidden">
				{/* Top progress bar */}
				<div
					className="relative w-full h-2 overflow-hidden bg-gray-100 rounded-t"
					aria-hidden="true"
					ref={progressBarRef}
				>
					<div
						className="absolute inset-x-0 top-0 w-full h-full transition-transform duration-[800ms] ease-in-out origin-left bg-bbx-spark"
						style={{ transform: `scaleX(${surveyProgress})` }}
					/>
				</div>

				<div className="flex flex-col p-8 md:p-12 lg:py-20">
					{/* Skip button */}
					{!surveyQuestions?.[currentIndex]?.isRequired ? (
						<button
							type="button"
							className="self-end mb-5 text-gray-500 md:mb-11 max-md:text-sm"
							onClick={() => {
								handleSkipSubmission(surveyQuestions?.[currentIndex]?.id)
								handleNext()
							}}
						>
							{commonTextNodes.survey.skip}
						</button>
					) : null}

					{/* Animates between survey questions */}
					{Array.isArray(surveyQuestions) &&
						surveyQuestions.map((questionObj, i) => {
							if (questionObj?.type === 'radio_select') {
								return (
									<RadioSelect
										surveyQuestion={questionObj}
										isRequired={questionObj?.isRequired}
										index={i}
										key={questionObj?.id}
									/>
								)
							} else if (questionObj?.type === 'range_slider') {
								return (
									<RangeSlider
										surveyQuestion={questionObj}
										isRequired={questionObj?.isRequired}
										index={i}
										key={questionObj?.id}
									/>
								)
							} else if (questionObj?.type === 'multiselect') {
								return (
									<MultiSelect
										surveyQuestion={questionObj}
										isRequired={questionObj?.isRequired}
										index={i}
										key={questionObj?.id}
									/>
								)
							}
							return null
						})}

					{/* Next | Back buttons */}
					<div className="flex gap-6 mt-14 md:mt-32 max-md:flex-col-reverse md:self-end">
						{/* Back button */}
						<button
							type="button"
							className="button button-secondary"
							onClick={() => {
								// If at first step close modal and go to previous step
								if (currentIndex === 0) {
									previousStep()
								} else {
									scrollToTop()
									setAnimateInReady(false)
									previousQuestion()
								}
							}}
							// Disabled when at first step of survey
							disabled={currentIndex === 0}
						>
							{commonTextNodes.survey.back}
						</button>

						{/* Next button */}
						<button
							type="button"
							className="button button-primary"
							disabled={nextButtonDisabled}
							onClick={handleNext}
						>
							{/* If it's the last question, show "Finish" */}

							{surveyQuestions?.[currentIndex]?.id ===
							surveyQuestions?.[surveyQuestions.length - 1]?.id
								? commonTextNodes.survey.finish
								: commonTextNodes.survey.next}
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
