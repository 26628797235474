import { cartDetailsFragment } from '../queries/get-cart-query'

/**
 * @see https://shopify.dev/api/storefront/2022-07/mutations/cartdiscountcodesupdate
 */
const cartDiscountCodesUpdateMutation = /* GraphQL */ `
	mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
		cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
			cart {
				...cartDetails
			}
			userErrors {
				code
				field
				message
			}
		}
	}

	${cartDetailsFragment}
`

export default cartDiscountCodesUpdateMutation
