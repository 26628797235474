import type menuData from '@data/menu.json'

import { colUrl } from '@lib/constants'
import type { Breadcrumbs } from '@lib/types'
import { hasWhiteSpace, startsWithUppercase } from '@lib/utils'

export function getProductBreadcrumbs(collectionPaths: string[], items: typeof menuData.items) {
	const allBreadcrumbs = getAllBreadcrumbs(collectionPaths, items)

	if (!allBreadcrumbs) return null

	if (allBreadcrumbs.length === 1) {
		return allBreadcrumbs[0]
	}

	const sortedBreadcrumbsByArrayLength = allBreadcrumbs.sort((breadcrumbA, breadcrumbB) => {
		if (breadcrumbA.length > breadcrumbB.length) return -1
		else if (breadcrumbA.length < breadcrumbB.length) return 1
		return 0
	})

	const largerBreadcrumb = sortedBreadcrumbsByArrayLength[0]

	return largerBreadcrumb
}

function getAllBreadcrumbs(collectionPaths: string[], items: typeof menuData.items) {
	const allBreadcrumbs = []

	for (const path of collectionPaths) {
		const breadcrumb = getBreadcrumbs(path, items)

		if (breadcrumb) {
			allBreadcrumbs.push(breadcrumb)
		}
	}

	return allBreadcrumbs
}

export function getBreadcrumbs(collectionPath: string, items: typeof menuData.items) {
	if (collectionPath) {
		const breadcrumbs = findBreacrumbRecursively(items, collectionPath)

		if (breadcrumbs) return breadcrumbs
	}

	return null
}

function findBreacrumbRecursively(
	items: typeof menuData.items,
	path: string,
	ancestors: Breadcrumbs = []
): Breadcrumbs | null {
	const shouldSearchTitle = startsWithUppercase(path) || hasWhiteSpace(path)

	const isWantedItem = shouldSearchTitle ? isItemTitle : isItemUrl

	for (const item of items) {
		if (isWantedItem(item, path)) {
			const currentBreadcrumb = getItemAsBreadcrumb(item)

			return [...ancestors, currentBreadcrumb]
		}
		const itemItems: any[] = (item as any).items ?? []

		if (itemItems.length > 0) {
			const newAncestors: Breadcrumbs = [...ancestors, getItemAsBreadcrumb(item)]

			const breadcrumbs = findBreacrumbRecursively(
				itemItems as typeof menuData.items,
				path,
				newAncestors
			)

			// if this condition fails it means the item we want is not found yet, i.e. reached `return null`
			if (breadcrumbs) {
				// filter out collections that are linking to  "All collections" in Shopify
				const filteredBreadcrumbs = breadcrumbs.filter(
					(breadcrumb) =>
						breadcrumb.path !== `${colUrl}${colUrl}` && breadcrumb.path.endsWith('.com#') === false
				)

				// breadcrumb has finished searching, return it
				return filteredBreadcrumbs
			}
		}
	}

	// returning false means the item is not in this 'branch'
	return null
}

const isItemTitle = (item: { title: string }, title: string) => item.title === title
const isItemUrl = (item: { url: string }, handle: string) => item.url.split('/').pop() === handle

function getItemAsBreadcrumb(item: { title: string; url: string }) {
	const collectionHandle = item.url.split('/').pop() as string

	const path = `${colUrl}/${collectionHandle}`

	return { title: item.title, path }
}
