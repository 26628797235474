import { getSession } from 'next-auth/react'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

declare global {
	interface Window {
		dataLayer: any
	}
}

let lastURL: string

export const pageview = async (url: string) => {
	const sanitizedUrl = url.split('?').shift()
	if (lastURL === sanitizedUrl) {
		return
	}
	lastURL = url
	const session = await getSession()

	// TODO: FIX THIS based on "Measurement plan for Birchbox"
	window.dataLayer.push({
		event: 'pageview',
		page: {
			pageName: sanitizedUrl,
			pageType: getPageType(sanitizedUrl),
			pageTitle: document.title
		},
		loginStatus: session ? 'logged_in_user' : 'not_logged_in'
	})
}

export const gtmEvent = ({ event, data }: any) => {
	if (typeof window !== 'undefined' && !!window?.dataLayer)
		window.dataLayer.push({ event, ...data })
}

/**
 * Returns the page type for the given url
 * @param pathname pathname (ex: /products/no-poreblem-primer)
 * @returns string page type (ex: product)
 */
const getPageType = (pathname: string | undefined) => {
	if (!pathname) return 'unknown'

	if (pathname === '/') return 'home'
	else if (pathname === '/membership') {
		return 'membership'
	} else if (pathname.includes('/collections') || pathname.includes('/categorias')) {
		return 'collection'
	} else if (pathname.includes('/products/') || pathname.includes('/producto/')) {
		return 'product'
	} else if (pathname.includes('/checkout')) {
		return 'checkout'
	} else if (pathname.includes('/profile') || pathname.includes('/cuenta')) {
		return 'profile'
	} else if (pathname.includes('/magazine') || pathname.includes('/blog')) {
		return 'magazine'
	} else if (pathname.includes('/about') || pathname.includes('/quienes-somos')) {
		return 'about'
	} else if (
		[
			'/terms-conditions',
			'/privacy-policy',
			'/accessibility',
			'/terminos-y-condiciones',
			'/politica-de-privacidad'
		].includes(pathname)
	) {
		return 'about'
	} else if (pathname.includes('/search') || pathname.includes('/buscar')) {
		return 'search'
	} else if (pathname.includes('/cart')) {
		return 'cart'
	} else if (pathname.includes('/login')) {
		return 'login'
	} else if (pathname.includes('/register') || pathname.includes('/registrarse')) {
		return 'register'
	}

	return 'unknown'
}
