import { HIGHLIGHT_ICONS } from '@lib/product/icon-mapping'
import shouldHideVariant from '@lib/product/should-hide-variant'
import type {
	Highlights,
	Metafield,
	MetafieldRaw,
	Product,
	ProductCard,
	ProductRaw,
	ProductVariant,
	ProductVariantsRaw,
	SellingPlanGroupAdminRaw
} from '@lib/types'

import { normalizeImage, normalizeImages, normalizePrice } from './generic'

/**
 * Normalize product
 * @param product raw product from Shopify API
 * @returns sanitized product
 */
export const normalizeProduct = (
	product: ProductRaw,
	options?: { shouldOmitHiddenVariants: boolean }
): Product => {
	const normalizedVariants = product.variants
		? normalizeProductVariants(product.variants.nodes, product?.brand?.value, product?.title, {
				shouldOmitHiddenVariants: options?.shouldOmitHiddenVariants || false
		  })
		: []
	return {
		id: product.id,
		name: product.title,
		vendor: product.vendor,
		path: `/${product.handle}`,
		slug: product.handle.replace(/^\/+|\/+$/g, ''),
		priceRange: product.priceRange,
		options: product.options,
		images: normalizeImages(
			product.images,
			product.title,
			product.variants.nodes,
			product.brand?.value
		),

		collections: product.collections.nodes,

		variants: normalizedVariants,
		description: product.description,
		descriptionHtml: product.descriptionHtml,
		tags: product.tags,
		availableForSale: product.availableForSale,
		// price: product.priceRange.minVariantPrice, // use lowest variant price as default
		price: {
			amount: normalizePrice(product.priceRange.minVariantPrice.amount),
			currencyCode: product.priceRange.minVariantPrice.currencyCode
		},
		type: product.productType,
		requiresSellingPlan: product.requiresSellingPlan,

		videos: normalizeVideos(product.videos),
		sellingPlanGroups: normalizeSellingPlanGroups(product.sellingPlanGroups),
		ingredients: normalizeIngredients(product.ingredients),

		// metafields:
		notForSale: normalizeMetafield(product.notForSale),
		howToUse: normalizeMetafield(product.howToUse),
		brand: normalizeMetafield(product.brand),
		highlights: normalizeHighlights(product.highlights),
		avgRating: normalizeMetafield(product.avgRating),
		numReviews: normalizeMetafield(product.numReviews),
		promotionalDescription: normalizeMetafield(product.promotionalDescription)
	}
}

export const normalizeProductRecommendations = (recommendations: ProductRaw[]): ProductCard[] =>
	recommendations.map((product: ProductRaw) => {
		const minPrice = Math.min(
			...product.variants.nodes
				.filter((v) => !shouldHideVariant(v))
				.map((variant) => parseFloat(variant.priceV2.amount))
		).toFixed(2)
		return {
			id: product.id,
			name: product.title,
			image:
				product.images.edges && product.images.edges.length > 0
					? normalizeImage(product.images.edges[0].node)
					: null,
			price: {
				amount: minPrice,
				currencyCode: product.priceRange.minVariantPrice.currencyCode
			},
			handle: product.handle,
			avgRating: normalizeMetafield(product.avgRating),
			numReviews: normalizeMetafield(product.numReviews),
			vendor: product.vendor
		}
	})

const normalizeProductVariants = (
	variants: ProductVariantsRaw['nodes'],
	brand?: string,
	productName?: string,
	options?: {
		shouldOmitHiddenVariants: boolean
	}
): ProductVariant[] =>
	variants
		?.map((variant) => ({
			id: variant.id,
			name: variant.title,
			title: variant.title,
			sku: variant.sku ?? variant.id,
			price: {
				amount: normalizePrice(variant.priceV2.amount),
				currencyCode: variant.priceV2.currencyCode
			},
			compareAtPrice: variant.compareAtPriceV2
				? {
						amount: normalizePrice(variant.compareAtPriceV2.amount),
						currencyCode: variant.compareAtPriceV2.currencyCode
				  }
				: null,

			listPrice: +(variant.compareAtPriceV2?.amount || 0),
			requiresShipping: variant.requiresShipping,
			availableForSale: variant.availableForSale,
			quantityAvailable: variant.quantityAvailable,
			selectedOptions: variant.selectedOptions,
			image: variant.image
				? normalizeImage(
						variant.image,
						`${brand ?? ''} ${productName ?? ''} ${variant.title ?? ''} swatch`.trim?.()
				  )
				: null,

			weight: variant.weight,
			weightUnit: variant.weightUnit,

			priceV2: {
				amount: normalizePrice(variant.priceV2.amount),
				currencyCode: variant.priceV2.currencyCode
			},

			// metafields:
			isSample: variant.isSample?.value.toLowerCase().trim() === 'true',
			hide: variant.hide?.value.toLowerCase().trim() === 'true',
			swatch: normalizeMetafield(variant.swatch)
		}))
		.filter((variant) => {
			if (options?.shouldOmitHiddenVariants && (variant.isSample || variant.hide)) {
				return false
			}
			return Boolean(variant)
		})

// TODO: check if it can be replaced with normalizeProduct
export const normalizeProductConnection = ({ edges }: any) =>
	edges?.map(({ node: { id, title, handle, images, variants, ...rest } }: any) => ({
		id,
		name: title,
		path: `/${handle}`,
		handle,
		images: normalizeImages(images, title, variants.nodes),
		variants: variants ? normalizeProductVariants(variants) : [],
		...rest
	}))

export const normalizeVideos = ({ nodes }: ProductRaw['videos']): Product['videos'] => {
	const normalizedVideos = nodes.filter((video) => video?.id !== undefined)

	// return null instead of an empty array if there are no videos
	return normalizedVideos.length ? normalizedVideos : null
}

export const normalizeSellingPlanGroups = ({ edges }: ProductRaw['sellingPlanGroups']) =>
	edges.map(({ node: groupNode }) => {
		const { sellingPlans, name } = groupNode
		const { edges: sellingPlansEdges } = sellingPlans

		const mapped = sellingPlansEdges.map(({ node: planNode }) => planNode)

		return {
			sellingPlans: mapped,
			name
		}
	})

export const normalizeSellingPlanGroupsAdmin = ({ nodes }: { nodes: SellingPlanGroupAdminRaw[] }) =>
	nodes.map(({ sellingPlans }) => {
		const { nodes: sellingPlanNodes } = sellingPlans

		return {
			sellingPlans: sellingPlanNodes
		}
	})

export const normalizeIngredients = (metafield: MetafieldRaw | null | undefined): string[] => {
	const normalizedMetafield = normalizeMetafield(metafield)
	try {
		return JSON.parse(normalizedMetafield.replace(/'/g, '"'))
	} catch (error) {
		// return an empty array, since the data is not in the correct format
		return []
	}
}

export const normalizeMetafield = (metafield: MetafieldRaw | null | undefined) => {
	if (metafield) {
		if (metafield.type === 'json') {
			try {
				return JSON.parse(metafield.value)
			} catch (e) {
				// do nothing since the data are probably malformed
			}
		} else if (metafield.type === 'number_integer') {
			return parseInt(metafield.value, 10)
		} else if (metafield.type === 'number_decimal') {
			return parseFloat(metafield.value)
		} else if (metafield.type === 'file_reference') {
			return metafield.reference ? metafield.reference : null
		} else if (metafield.type === 'boolean') {
			return metafield.value.toLowerCase().trim() === 'true'
		}

		return metafield.value
	}

	return metafield
}

const normalizeHighlights = (highlightsMetafield: Metafield | null): Highlights | null => {
	if (highlightsMetafield) {
		const highlightsArray = normalizeMetafield(highlightsMetafield) as string[]

		const highlights = highlightsArray.map((highlight) => ({
			name: highlight,
			icon:
				HIGHLIGHT_ICONS.find(({ name }) => name.toLowerCase() === highlight.toLowerCase())?.icon ||
				'mortar-pestle' // default icon
		}))

		return highlights
	}

	return null
}
