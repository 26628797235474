export const freeGroundShippingMins = new Map([
	[
		'en-us',
		{
			currencyCode: 'USD',
			amount: '50'
		}
	],
	[
		'en-gb',
		{
			currencyCode: 'GBP',
			amount: '30'
		}
	],
	[
		'es-es',
		{
			currencyCode: 'EUR',
			amount: '35'
		}
	]
])

export const freeTwoDayShippingMins = new Map([
	[
		'en-us',
		{
			currencyCode: 'USD',
			amount: '150'
		}
	],
	[
		'en-gb',
		{
			currencyCode: 'GBP',
			amount: '99999'
		}
	],
	[
		'es-es',
		{
			currencyCode: 'EUR',
			amount: '99999'
		}
	]
])
