import dynamic from 'next/dynamic'

import { lang } from '@lib/constants'
import useCookiesSettings from '@lib/hooks/useCookiesSettings'

export default function CookieSettings() {
	const { cookieSettings, cookiesLoaded } = useCookiesSettings()

	// has cookie settings, do not render anything
	if (lang !== 'en-us' && !cookieSettings && cookiesLoaded) {
		const CookieSettingsView = dynamic(() => import('./CookieSettingsView'))
		return <CookieSettingsView />
	}

	return null
}
