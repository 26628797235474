import { FULFILLMENT_DISPLAY_STATUS, ORDER_FULFILLMENT_STATUS } from '@lib/constants'
import fetcher, { adminFetcher } from '@lib/fetcher'
import getCustomerOrdersFulfillmentsAndTagsQuery from '@lib/queries/get-customer-orders-fulfillments-and-tags-query'
import getCustomerOrdersQuery from '@lib/queries/get-customer-orders-query'
import getOrderFulfillmentAndTagsQuery from '@lib/queries/orders/get-order-fulfillments-and-tags-query'
import type { Customer } from '@lib/types/customer'
import type {
	FulfillmentDisplayStatus,
	LineItem,
	LineItems,
	Order,
	OrderStatus,
	OrderTags,
	Orders,
	OrdersFulfillment,
	OrdersFulfillments,
	ShipmentProgress
} from '@lib/types/order'
import type { RawLineItem, RawOrder, RawOrderTags, RawOrdersFulfillment } from '@lib/types/raw'
import { formatDateString } from '@lib/utils/dates'
import { normalizeMetafield } from '@lib/utils/normalizers'

export async function getCustomerOrders(customerAccessToken: string, numOrders = 250) {
	const customerOrdersQuery = await fetcher({
		variables: { customerAccessToken, numOrders },
		query: getCustomerOrdersQuery
	})

	if (customerOrdersQuery.customer?.orders?.nodes?.length) {
		const { orders } = customerOrdersQuery.customer

		return normalizeOrders(orders.nodes)
	}

	return null
}

export async function getOrdersFulfillmentsAndTags(
	customerId: Customer['id']
): Promise<OrdersFulfillments | null> {
	const customerOrdersQuery = await adminFetcher({
		variables: { id: customerId },
		query: getCustomerOrdersFulfillmentsAndTagsQuery
	})

	if (customerOrdersQuery.customer?.orders?.nodes?.length) {
		const { orders } = customerOrdersQuery.customer

		return normalizeOrderFulfillmentsAndTags(orders.nodes)
	}

	return null
}

export async function getOrderFulfillmentAndTags(
	orderId: string
): Promise<OrdersFulfillment | null> {
	const id = `gid://shopify/Order/${orderId.split('/').pop()}`
	const orderQuery = await adminFetcher({
		variables: { id },
		query: getOrderFulfillmentAndTagsQuery
	})

	if (orderQuery.order) {
		return normalizeOrderFulfillmentAndTags(orderQuery.order)
	}

	return null
}

export async function getOrdersFulfillmentsAndTagsFromAdminAPI(customerId: Customer['id']) {
	const encodedCustomerId = encodeURIComponent(customerId)

	const ordersFulfillmentsAndTagsResponse = await fetch(
		`/api/shopify/customer/${encodedCustomerId}/orders-fulfillments-and-tags`
	)

	const ordersFulfillmentsAndTagsJson = await ordersFulfillmentsAndTagsResponse.json()

	const ordersFulfillmentsAndTags: OrdersFulfillments = ordersFulfillmentsAndTagsJson.data

	return ordersFulfillmentsAndTags
}

export async function getOrderFulfillmentAndTagsFromAdminAPI(orderId: string) {
	const encodedOrderId = encodeURIComponent(orderId)

	const ordersFulfillmentAndTagsResponse = await fetch(`/api/shopify/order/${encodedOrderId}`)

	const orderFulfillmentAndTagsJson = await ordersFulfillmentAndTagsResponse.json()

	const orderFulfillmentAndTags: OrdersFulfillment | null = orderFulfillmentAndTagsJson.data

	return orderFulfillmentAndTags
}

type OrderFulfillmentItem = typeof ORDER_FULFILLMENT_STATUS[Order['fulfillmentStatus']]
type FulfillmentDisplayStatusItem = typeof FULFILLMENT_DISPLAY_STATUS[FulfillmentDisplayStatus]

type GetOrderStatusReturn = {
	status: OrderStatus
	message: OrderFulfillmentItem | FulfillmentDisplayStatusItem
}

export function getOrderStatus(order: Order): GetOrderStatusReturn {
	// Noticed some errors in Sentry using the "at()" method;
	// seems to be from older browsers that do not support it.
	// REMOVED: fulfillments?.at(-1)
	const lastFulfillment = order?.fulfillments?.slice(-1)?.[0]

	const lastFulfillmentHasStatus =
		typeof lastFulfillment?.displayStatus === 'string' &&
		String(lastFulfillment?.displayStatus) in FULFILLMENT_DISPLAY_STATUS

	if (lastFulfillmentHasStatus) {
		const status = lastFulfillment.displayStatus as FulfillmentDisplayStatus

		// if status is DELIVERED, use deliveredAt date
		if (status === 'DELIVERED' && lastFulfillment.deliveredAt) {
			const formattedDate = formatDateString(lastFulfillment.deliveredAt, {
				month: 'short',
				day: 'numeric',
				year: 'numeric'
			})

			if (formattedDate) {
				const message = `Delivered ${formattedDate}` as FulfillmentDisplayStatusItem

				return {
					status,
					message
				}
			}
		}

		return { status, message: FULFILLMENT_DISPLAY_STATUS[status] }
	}

	return {
		status: order.fulfillmentStatus,
		message: ORDER_FULFILLMENT_STATUS[order.fulfillmentStatus]
	}
}

export function getShipmentProgress(orderStatus: GetOrderStatusReturn): ShipmentProgress {
	const statusToOrderProgress = {
		UNFULFILLED: null,

		// showing as "delivered" for both MARKED_AS_DELIVERED and DELIVERED
		// for now (until we have a better way to handle this).
		MARKED_AS_FULFILLED: 3,
		FULFILLED: 3,

		IN_PROGRESS: 1,
		IN_TRANSIT: 2,
		DELIVERED: 3
	} as {
		[key in OrderStatus]: ShipmentProgress
	}

	return statusToOrderProgress[orderStatus.status] ?? null
}

function normalizeOrders(orders: RawOrder[]): Orders {
	return orders.map(normalizeOrder)
}

function normalizeOrder(order: RawOrder): Order {
	const migratedOrderDate = normalizeMetafield(order.migratedCreatedAt)

	return {
		id: order.id,
		processedAt: migratedOrderDate ?? order.processedAt,
		lineItems: normalizeLineItems(order.lineItems.nodes),
		orderNumber: order.orderNumber,
		financialStatus: order.financialStatus,
		fulfillmentStatus: order.fulfillmentStatus,
		currentTotalPrice: order.currentTotalPrice,
		subscription: normalizeMetafield(order.subscription)
	}
}

function normalizeLineItems(lineItems: RawLineItem[]): LineItems {
	return lineItems.map(normalizeLineItem)
}

function normalizeLineItem(lineItem: RawLineItem): LineItem {
	return {
		title: lineItem.title,
		quantity: lineItem.currentQuantity,
		variant: lineItem.variant
			? {
					id: lineItem.variant.id,
					title: lineItem.variant.title,
					image: lineItem.variant.image,
					sku: lineItem.variant.sku,
					handle: lineItem.variant.product.handle
			  }
			: null
	}
}

function normalizeOrderFulfillmentAndTags(
	order: RawOrdersFulfillment & { tags: RawOrderTags }
): OrdersFulfillment & { tags: OrderTags } {
	return {
		id: order.id,
		tags: order.tags,
		cancelledAt: order.cancelledAt,
		processedAt: order.processedAt || undefined,
		createdAt: order.createdAt,
		customerId: order.customer?.id,
		fulfillments: order.fulfillments.map((fulfillment) => ({
			deliveredAt: fulfillment.deliveredAt,
			displayStatus: fulfillment.displayStatus,
			inTransitAt: fulfillment.inTransitAt,
			trackingInfo: fulfillment.trackingInfo
		}))
	}
}

function normalizeOrderFulfillmentsAndTags(
	ordersFulfillmentsAndTags: (RawOrdersFulfillment & { tags: RawOrderTags })[]
): (OrdersFulfillment & { tags: OrderTags })[] {
	return ordersFulfillmentsAndTags.map(normalizeOrderFulfillmentAndTags)
}
