import { CURRENCY_MAP, lang } from '@lib/constants'
import type { ProductPrice } from '@lib/types/product'

type Props = {
	price: ProductPrice
}

const baseNumberOptions: Intl.NumberFormatOptions = {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
}

export default function Price({ price }: Props) {
	return (
		<>
			{lang !== 'es-es' && CURRENCY_MAP.get(price.currencyCode)}
			{Number(price.amount).toLocaleString(lang, baseNumberOptions)}
			{lang === 'es-es' && CURRENCY_MAP.get(price.currencyCode)}
		</>
	)
}
