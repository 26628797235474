import { cartDetailsFragment } from '../queries/get-cart-query'

/**
 * @see https://shopify.dev/api/storefront/2022-04/mutations/cartLinesRemove
 */
const cartLinesRemoveMutation = /* GraphQL */ `
	mutation cartLinesRemoveMutation($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			userErrors {
				code
				field
				message
			}
			cart {
				...cartDetails
			}
		}
	}

	${cartDetailsFragment}
`
export default cartLinesRemoveMutation
