import discounts from '@data/discounts.json'

import type { CartItem } from '@lib/types/cart'

import { sha256 } from './utils'

interface DiscountJSONCustomerBuys {
	value: {
		quantity?: string // eg. '1'
		amount?: string // eg. '50.00'
	}
	items: {
		products?: { nodes: { id: string }[] }
		collections?: { nodes: { id: string }[] }
	}
}

interface DiscountJSONCustomerGets {
	variants: { id: string; price: string }[]
}

interface DiscountJSON {
	id: string
	codeDiscount: {
		__typename: string
		codes: string[]
		customerBuys: DiscountJSONCustomerBuys
		customerGets: DiscountJSONCustomerGets
	}
}

type Params = {
	productsInCart: CartItem[]
	code: string
}

async function shouldUseDiscount({ productsInCart, code }: Params) {
	const hashedCode = await sha256(code)

	// Find the discount with the code that they used
	const codeDiscountNode = (discounts as DiscountJSON[]).find((codeDiscount) =>
		codeDiscount.codeDiscount.codes.includes(hashedCode)
	)

	if (!codeDiscountNode) {
		return []
	}

	const { codeDiscount } = codeDiscountNode
	const { customerBuys, customerGets } = codeDiscount

	if (
		(undefined === customerBuys.items.collections && undefined === customerBuys.items.products) ||
		(undefined === customerBuys.value.amount && undefined === customerBuys.value.quantity)
	) {
		// Invalid cases. Normally they won't happen
		return []
	}

	let productsThatAreApplicable: {
		node: CartItem
	}[] = []

	// Apply discount to products in collections.
	if (undefined !== customerBuys.items.collections) {
		// The collection IDs that this discount applies to
		const applicableCollectionIds = customerBuys.items.collections.nodes.map((col) => col.id) || []

		// The variable name gives this one away 😮‍💨
		// This is, basically, the products that are applicable for the discount
		// Because they share a collection with the collections that the discount applies to
		// @ts-ignore
		productsThatAreApplicable = productsInCart.edges.filter(
			({ node: product }: { node: CartItem }) => {
				const collectionsForThisProductIds = product.merchandise.product.collections.nodes.map(
					(node) => node.id
				)

				// if applicableCollectionIds or collectionsForThisProductIds share any ids, return true
				return collectionsForThisProductIds.some((id) => applicableCollectionIds.includes(id))
			}
		)
	}
	// Apply discount to specific products.
	else if (undefined !== customerBuys.items.products) {
		const applicableProductsIds = customerBuys.items.products.nodes.map((col) => col.id) || []

		// @ts-ignore
		productsThatAreApplicable = productsInCart.edges.filter(({ node }: { node: CartItem }) =>
			applicableProductsIds.includes(node.merchandise.product.id)
		)
	}

	// Minimum purchase amount
	if (undefined !== customerBuys.value.amount) {
		// Get total subtotal of the applicable products, so that we can check if it's greater than the necessary prices
		const totalCostOfProductsThatAreApplicable = productsThatAreApplicable.reduce(
			(acc, { node: line }) => acc + parseFloat(line.estimatedCost.subtotalAmount.amount),
			0
		)

		return totalCostOfProductsThatAreApplicable >= parseFloat(customerBuys.value.amount)
			? customerGets.variants
			: []
	}

	// Minimum quantity of items
	if (undefined !== customerBuys.value.quantity) {
		return productsThatAreApplicable.length >= parseFloat(customerBuys.value.quantity)
			? customerGets.variants
			: []
	}

	return []
}

export default shouldUseDiscount
