import { useEffect, useState } from 'react'

import { useSession } from 'next-auth/react'
import dynamic from 'next/dynamic'

import { useHandleSubmitSurvey, useSurvey } from '@context/subscription-box/useSurvey'

import surveyAuthContent from '@data/i18n/survey-modals.json'

import useCustomer from '@lib/hooks/useCustomer'

import Modal from '@components/Modal'
import Survey from '@components/subscription-box/Survey'

const Auth = dynamic(() => import('@components/profile/Auth'))
const CurateSubscriptionBox = dynamic(
	() => import('@components/subscription-box/CurateSubscriptionBox')
)

type SurveyAuthModalParams = {
	isModalOpen: boolean
	closeModal: () => void
}

// Modal for survey followed by login/signup
export default function SurveyAuthModal({ isModalOpen, closeModal }: SurveyAuthModalParams) {
	const [modalStep, setModalStep] = useState<'survey' | 'sign-up' | 'curate'>('survey')
	const { surveyId } = useSurvey() || {}
	const { customer } = useCustomer()

	const [surveyComplete, setSurveyComplete] = useState(false)

	const submitSurvey = useHandleSubmitSurvey()

	const { data: session } = useSession()

	// Handle skip auth step if already logged in
	useEffect(() => {
		if (modalStep === 'sign-up' && session?.shopifyAccessToken) {
			setModalStep('curate')
		}
	}, [modalStep, session?.shopifyAccessToken])

	// Survey submit logic
	useEffect(() => {
		// If not logged in
		if (!customer?.id) return

		// If survey is not completed
		if (!surveyComplete) return

		// Submit the survey
		submitSurvey(customer.id)

		// Go to curate step in modal
		setModalStep('curate')
	}, [customer, surveyComplete, submitSurvey])

	return (
		<Modal isOpen={isModalOpen} closeModal={closeModal} desktopStyle="full-screen">
			<div className="flex flex-col items-center pb-20 pt-7 lg:pt-20">
				{modalStep !== 'curate' && (
					<div className="flex flex-col gap-2 px-4 mb-10">
						{/* Title */}
						<h1 className="text-xl md:text-3xl text-center font-serif">
							{modalStep === 'survey'
								? surveyAuthContent['new-user'].surveyTitle
								: surveyAuthContent['new-user'].authTitle}
						</h1>

						{/* Description */}
						<p className="font-medium text-center text-gray-500 max-md:text-sm">
							{modalStep === 'survey'
								? surveyAuthContent['new-user'].surveyDescription
								: surveyAuthContent['new-user'].authDescription}
						</p>
					</div>
				)}

				{surveyId && modalStep === 'survey' && (
					<Survey
						callBack={() => {
							setSurveyComplete(true)
							setModalStep('sign-up')
						}}
					/>
				)}

				{/* If step is create-account and user is not signed in */}
				{modalStep === 'sign-up' && !session ? <Auth subscriptionBox step="sign-up" /> : null}

				{modalStep === 'curate' && <CurateSubscriptionBox />}
			</div>
		</Modal>
	)
}
