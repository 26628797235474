import useSWR from 'swr'
import { useSession } from 'next-auth/react'
import { initializeRecharge, loginWithShopify } from '@lib/operations/recharge'

type UseRechargeSessionReturn = {
    rechargeSession: any | undefined | null
    loading: boolean
    revalidate: () => void
}


const fetchRechargeSession = async (accessToken: string | undefined) => {
    initializeRecharge(accessToken)
    if (!accessToken) {
        throw new Error("Shopify access token not available.")
    }
    return loginWithShopify(accessToken)
}

export default function useRechargeSession(): UseRechargeSessionReturn {
    const { data: sessionData, status } = useSession()
    const accessToken = sessionData?.shopifyAccessToken as string | undefined

    const { data: rechargeSession, error, isValidating, mutate } = useSWR(
        accessToken ? ['/recharge-session', accessToken] : null,
        () => fetchRechargeSession(accessToken)
    )

    return {
        rechargeSession: error ? null : rechargeSession,
        loading: status === 'loading' || isValidating,
        revalidate: mutate
    }
}