// eslint-disable-next-line import/prefer-default-export
export const metafieldFragment = /* GraphQL */ `
	fragment metafield on Metafield {
		id
		description
		key
		namespace
		type
		value
	}
`
