import Link from 'next/link'

import CommonTextNodes from '@data/i18n/common.json'

import { colUrl, lang } from '@lib/constants'
import type { Language } from '@lib/types/lang'

import Logo from '@components/global/Logo'

import NewsletterSignUp from './NewsletterSignUp'

type FooterNav = {
	[key in Language]: {
		items: NavColumn[]
	}
}

type NavColumn = {
	title: string
	id: number
	items: NavItem[]
}

type NavItem = {
	title: string
	id: number
	url: string
}

const navItems: FooterNav = {
	'en-us': {
		items: [
			{
				title: 'Products',
				id: 1,
				items: [
					// {
					// 	title: 'Makeup',
					// 	id: 1,
					// 	url: '/collections/makeup'
					// },
					// {
					// 	title: 'Hair',
					// 	id: 2,
					// 	url: '/collections/hair'
					// },
					// {
					// 	title: 'Skincare',
					// 	id: 3,
					// 	url: '/collections/skincare'
					// },
					// {
					// 	title: 'Body',
					// 	id: 4,
					// 	url: '/collections/body'
					// }
					{
						title: 'Members Market',
						id: 4,
						url: '/members-market'
					}
				]
			},
			{
				title: 'Company',
				id: 2,
				items: [
					{
						title: 'About',
						id: 1,
						url: '/about'
					},
					{
						title: 'Careers',
						id: 2,
						url: 'https://www.linkedin.com/jobs/search/?currentJobId=3141303065&f_C=946261&geoId=92000000'
					},
					{
						title: 'Help Center',
						id: 3,
						url: 'https://help.birchbox.com/'
					}
				]
			},
			{
				title: 'Legal',
				id: 3,
				items: [
					{
						title: 'Terms',
						id: 1,
						url: '/terms-conditions'
					},
					{
						title: 'Privacy',
						id: 2,
						url: '/privacy-policy'
					},
					{
						title: 'Accessibility',
						id: 3,
						url: '/accessibility'
					}
				]
			}
		]
	},
	'en-gb': {
		items: [
			{
				title: 'Products',
				id: 1,
				items: [
					{
						title: 'Makeup',
						id: 1,
						url: '/collections/makeup'
					},
					{
						title: 'Hair',
						id: 2,
						url: '/collections/hair'
					},
					{
						title: 'Skincare',
						id: 3,
						url: '/collections/skincare'
					},
					{
						title: 'Body',
						id: 4,
						url: '/collections/body'
					},
					{
						title: 'Limited Edition',
						id: 5,
						url: '/collections/limited-editions'
					}
				]
			},
			{
				title: 'Company',
				id: 2,
				items: [
					{
						title: 'About',
						id: 1,
						url: '/about'
					},
					{
						title: 'Careers',
						id: 2,
						url: 'https://www.linkedin.com/jobs/search/?currentJobId=3141303065&f_C=946261&geoId=92000000'
					},
					{
						title: 'Help Centre',
						id: 3,
						url: 'https://help.birchbox.com'
					}
				]
			},
			{
				title: 'Legal',
				id: 3,
				items: [
					{
						title: 'Terms',
						id: 1,
						url: '/terms-conditions'
					},
					{
						title: 'Privacy',
						id: 2,
						url: '/privacy-policy'
					}
				]
			}
		]
	},
	'es-es': {
		items: [
			{
				title: 'Productos',
				id: 1,
				items: [
					{
						title: 'Ediciones Limitadas',
						id: 1,
						url: `${colUrl}/ediciones-limitadas`
					},
					{
						title: 'Maquillaje',
						id: 2,
						url: `${colUrl}/maquillaje`
					},
					{
						title: 'Facial',
						id: 3,
						url: `${colUrl}/facial`
					},
					{
						title: 'Capilar',
						id: 4,
						url: `${colUrl}/capilar`
					},
					{
						title: 'Corporal',
						id: 5,
						url: `${colUrl}/corporal`
					}
				]
			},
			{
				title: 'Empresa',
				id: 2,
				items: [
					{
						title: 'Contacto',
						id: 1,
						url: 'https://soporte.birchbox.es/hc/es-es'
					},
					{
						title: 'Quiénes somos',
						id: 2,
						url: '/quienes-somos'
					},
					{
						title: 'Trabaja con nosotros',
						id: 3,
						url: 'https://www.linkedin.com/jobs/search/?currentJobId=3141303065&f_C=946261&geoId=92000000'
					},
					{
						title: 'Prensa',
						id: 4,
						url: '/prensa-comunicacion-birchbox'
					}
				]
			},
			{
				title: 'Legal',
				id: 3,
				items: [
					{
						title: 'Términos y condiciones',
						id: 1,
						url: '/terminos-y-condiciones'
					},
					{
						title: 'Política de privacidad',
						id: 2,
						url: '/politica-de-privacidad'
					},
					{
						title: 'Política de cookies',
						id: 3,
						url: '/politica-de-cookies'
					},
					{
						title: 'Aviso Legal',
						id: 4,
						url: '/aviso-legal'
					}
				]
			}
		]
	}
}

const footerSocialLinks = [
	{
		title: 'Instagram',
		icon: 'fa-instagram',
		url: CommonTextNodes.footer.socialUrls.Instagram
	},
	{
		title: 'Pinterest',
		icon: 'fa-pinterest',
		url: CommonTextNodes.footer.socialUrls.Pinterest
	},
	{
		title: 'TikTok',
		icon: 'fa-tiktok',
		url: CommonTextNodes.footer.socialUrls.TikTok
	},
	{
		title: 'YouTube',
		icon: 'fa-youtube',
		url: CommonTextNodes.footer.socialUrls.YouTube
	},
	{
		title: 'Twitter',
		icon: 'fa-twitter',
		url: CommonTextNodes.footer.socialUrls.Twitter
	},
	{
		title: 'Facebook',
		icon: 'fa-facebook',
		url: CommonTextNodes.footer.socialUrls.Facebook
	}
]

export default function Footer() {
	return (
		<footer className="border-t section">
			<div className="container">
				{/* Logo */}
				<Link href="/" prefetch={false}>
					<a className="inline-flex mb-12" title="Birchbox">
						<Logo className="w-28 fill-bbx-style" />
					</a>
				</Link>

				{/* Rest of footer */}
				<div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
					{/* Left side */}
					{/* Footer nav items */}
					{Array.isArray(navItems[lang as Language]?.items) && (
						<div className="grid grid-cols-1 gap-6 xs:grid-cols-3">
							{navItems[lang as Language].items.map(({ title, id, items }) => (
								<div key={id}>
									{title && <h2 className="mb-4 text-lg font-medium">{title}</h2>}

									{Array.isArray(items) && (
										<div className="flex flex-col gap-3" key={id}>
											{items.map(({ title: itemTitle, id: itemId, url: itemUrl }) =>
												itemTitle ? (
													<Link href={itemUrl} prefetch={false} key={itemId}>
														<a className="text-gray-500 nav-item">{itemTitle}</a>
													</Link>
												) : null
											)}
										</div>
									)}
								</div>
							))}
						</div>
					)}

					{/* Right */}
					{/* Email form and social links */}
					<div className="flex flex-col gap-y-4">
						<NewsletterSignUp />

						{/* Social links */}
						<div className="flex gap-x-7">
							{footerSocialLinks.map(({ title, icon, url }) => {
								// avoid links with invalid URLs
								if (typeof url !== 'string') return null

								return (
									<a
										key={icon}
										href={url}
										target="_blank"
										rel="noopener noreferrer"
										className="text-gray-500 transition-colors duration-150 hover:text-bbx-spark"
										title={title}
									>
										<i className={`text-xl fa-brands ${icon}`} />
									</a>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
