/**
 * @see https://shopify.dev/api/storefront/2022-01/objects/cart
 */
export const cartDetailsFragment = /* GraphQL */ `
	fragment cartDetails on Cart {
		id
		checkoutUrl
		estimatedCost {
			totalAmount {
				amount
				currencyCode
			}
			subtotalAmount {
				amount
				currencyCode
			}
			totalTaxAmount {
				amount
				currencyCode
			}
			totalDutyAmount {
				amount
				currencyCode
			}
		}
		discountCodes {
			applicable
			code
		}
		lines(first: 250) {
			edges {
				node {
					id
					sellingPlanAllocation {
						sellingPlan {
							name
							id
						}
					}
					estimatedCost {
						subtotalAmount {
							amount
							currencyCode
						}
						totalAmount {
							amount
							currencyCode
						}
					}
					discountAllocations {
						discountedAmount {
							amount
							currencyCode
						}
					}
					merchandise {
						... on ProductVariant {
							id
							sku
							title
							selectedOptions {
								name
								value
							}
							requiresShipping
							quantityAvailable
							image {
								url
								altText
								width
								height
							}
							product {
								handle
								title
								vendor
								id
								# Used for discount codes
								collections(first: 10) {
									nodes {
										id
										# Used for GTM categories
										handle
									}
								}
								tags
							}
						}
					}
					quantity
				}
			}
		}
	}
`

const getCartQuery = /* GraphQL */ `
	query getCart($cartId: ID!) {
		cart(id: $cartId) {
			...cartDetails
		}
	}
	${cartDetailsFragment}
`
export default getCartQuery
