import axios from 'axios'

import type { FetcherOptions } from '@lib/types/generic'

import { ADMIN_API_TOKEN, ADMIN_API_URL, API_TOKEN, API_URL } from './constants'
import { handleFetchResponse, handleSubscriptionFetchResponse } from './utils'

const getRandomAdminApiToken = () => {
	if (!ADMIN_API_TOKEN) {
		throw new Error('ADMIN_API_TOKEN is not defined')
	}
	const apiKeys = ADMIN_API_TOKEN.split(',')
	const randomIndex = Math.floor(Math.random() * apiKeys.length)
	return apiKeys[randomIndex].trim()
}

async function fetcher({
	url = API_URL,
	method = 'POST',
	variables,
	query,
	headers
}: FetcherOptions) {
	const { locale, ...vars } = variables ?? {}

	const response = await axios(url, {
		method,
		data: JSON.stringify({ query, variables: vars }),
		headers: {
			'X-Shopify-Storefront-Access-Token': API_TOKEN ?? null,
			'Content-Type': 'application/json',
			...(locale && {
				'Accept-Language': locale
			}),
			...headers
		}
	})

	return handleFetchResponse(response)
}

export async function adminFetcher(
	{ url = ADMIN_API_URL, method = 'POST', variables, query, headers }: FetcherOptions,
	mainApiToken = false
) {
	const { locale, ...vars } = variables ?? {}

	const adminAPIToken = mainApiToken
	// First api key 
		? (ADMIN_API_TOKEN as string).split(',')[0].trim()
		: getRandomAdminApiToken()

	const response = await axios(url, {
		method,
		data: JSON.stringify({ query, variables: vars }),
		headers: {
			'X-Shopify-Access-Token': adminAPIToken,
			'Content-Type': 'application/json',
			...(locale && {
				'Accept-Language': locale
			}),
			...headers
		}
	})

	return handleFetchResponse(response)
}

export async function subscriptionFetcher({
	method = 'POST',
	query,
	headers,
	variables
}: Omit<FetcherOptions, 'url'>) {
	const { locale, ...vars } = variables ?? {}

	const url = process.env.NEXT_PUBLIC_SUBSCRIPTIONS_ENDPOINT ?? ''

	const response = await axios(url, {
		method,
		data: JSON.stringify({ query, variables: vars }),
		headers: {
			'X-Shopify-Storefront-Access-Token': API_TOKEN ?? null,
			'Content-Type': 'application/json',
			...(locale && {
				'Accept-Language': locale
			}),
			...headers
		}
	})

	return handleSubscriptionFetchResponse(response)
}

export default fetcher
