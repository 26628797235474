import getCartId from '@lib/cart/get-cart-id'
import fetcher from '@lib/fetcher'
import cartLinesUpdateMutation from '@lib/mutations/cart-line-item-update'
import type { ProductRaw, ProductVariant, SellingPlan } from '@lib/types/product'

// eslint-disable-next-line import/no-cycle
import { ShopifyWebStorefrontClient } from '@components/cart/OnwardStorefrontClient'

export type UpdateItemParams = {
	id?: ProductRaw['id']
	variantId?: ProductVariant['id']
	quantity: number
	sellingPlanId?: SellingPlan['id']
	quantityAvailable?: ProductVariant['quantityAvailable']
}

/**
 * Update cart items using Shopify's cart API
 *
 * @see https://shopify.dev/api/examples/cart
 */
export default async function updateItem({
	id,
	variantId,
	quantity,
	sellingPlanId
}: UpdateItemParams) {
	const lines = [
		{
			id,
			merchandiseId: variantId,
			quantity,
			sellingPlanId
		}
	]

	const cartId = getCartId()

	if (!cartId) {
		return null
	}

	const { cartLinesUpdate } = await fetcher({
		query: cartLinesUpdateMutation,
		variables: {
			cartId,
			lines
		}
	})

	if (window.onwardApp && window.onwardClient) {
		window.onwardApp.cartChanged(ShopifyWebStorefrontClient.parseCart(cartLinesUpdate.cart))
	}

	return cartLinesUpdate
}
