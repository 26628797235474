import { createContext, useCallback, useContext, useReducer, useState } from 'react'
import type { ReactNode } from 'react'

import type { CustomizableSelectionType } from '@lib/types/subscription-box'

// Items picker state
type ProductPickerOverlayStateParams = {
	open: boolean
	gridIndex: number
}

const subscriptionBoxSteps = [
	'home',
	'curate',
	'customize-your-box',
	'view-and-edit-box',
	'review-and-confirm'
] as const

export type SubscriptionBoxStep = typeof subscriptionBoxSteps[number]

// Reducer initializer
const reducerInitializer = (step = '') => {
	if (step) {
		const specificStepPosition = subscriptionBoxSteps.findIndex(
			(currentStep) => currentStep === step
		)

		if (specificStepPosition >= 0) {
			return subscriptionBoxSteps[specificStepPosition]
		}
	}

	// Return first step by default - if no step is provided
	return subscriptionBoxSteps[0]
}

// Typeof return useSubscriptionBoxManager
type UseManagerResult = ReturnType<typeof useSubscriptionBoxStateManager>

// Subscription box stepper context with initial values
const SubscriptionBoxStateContext = createContext<UseManagerResult>({
	subscriptionBoxStep: subscriptionBoxSteps[0],
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	nextSubscriptionBoxStep: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	previousSubscriptionBoxStep: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	specificSubscriptionBoxStep: () => {},
	customizableSelectionType: 'CUSTOMIZED_BOX',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setCustomizableSelectionType: () => {},
	productPickerOverlayState: { open: false, gridIndex: 0 },
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setProductPickerOverlayState: () => {},
	homeScreenBoxState: ''
})

// Reducer action type
type ActionType = {
	type: 'NEXT' | 'PREVIOUS' | 'SPECIFIC'
	step?: SubscriptionBoxStep
}

function useSubscriptionBoxStateManager(step?: SubscriptionBoxStep, homeScreenBoxState?: string) {
	// Subscription box page step reducer
	const [subscriptionBoxStep, dispatch] = useReducer(
		(state: SubscriptionBoxStep, action: ActionType) => {
			// Find index of current step
			const index = subscriptionBoxSteps.findIndex((currentStep) => currentStep === state)

			switch (action.type) {
				case 'NEXT': {
					// Currently at last step
					if (index === subscriptionBoxSteps.length - 1) {
						return state
					}

					// Go to next step
					return subscriptionBoxSteps[index + 1]
				}

				case 'PREVIOUS': {
					// Currently at first step
					if (index === 0) {
						return state
					}
					// Go to previous step
					return subscriptionBoxSteps[index - 1]
				}

				case 'SPECIFIC': {
					if (action.step) {
						const specificStepPosition = subscriptionBoxSteps.findIndex(
							(currentStep) => currentStep === action.step
						)

						if (specificStepPosition >= 0) {
							return subscriptionBoxSteps[specificStepPosition]
						}
					}

					return state
				}

				default:
					return state
			}
		},
		step,
		reducerInitializer
	)

	const nextSubscriptionBoxStep = useCallback(() => {
		dispatch({
			type: 'NEXT'
		})
	}, [])

	const previousSubscriptionBoxStep = useCallback(() => {
		dispatch({
			type: 'PREVIOUS'
		})
	}, [])

	const specificSubscriptionBoxStep = useCallback((specificStep: SubscriptionBoxStep) => {
		dispatch({
			type: 'SPECIFIC',
			step: specificStep
		})
	}, [])

	const [customizableSelectionType, setCustomizableSelectionType] =
		useState<CustomizableSelectionType>('CUSTOMIZED_BOX')

	// Item picker overlay open state, and index of grid slot (where the chosen item fits into the grid)
	const [productPickerOverlayState, setProductPickerOverlayState] =
		useState<ProductPickerOverlayStateParams>({ open: false, gridIndex: 0 })

	return {
		subscriptionBoxStep,
		nextSubscriptionBoxStep,
		previousSubscriptionBoxStep,
		specificSubscriptionBoxStep,
		customizableSelectionType,
		setCustomizableSelectionType,
		productPickerOverlayState,
		setProductPickerOverlayState,
		homeScreenBoxState
	}
}

// Subscription box products provider
export function SubscriptionBoxStateProvider({
	children,
	step,
	homeScreenBoxState
}: {
	children: ReactNode
	step?: SubscriptionBoxStep
	homeScreenBoxState?: string
}) {
	return (
		<SubscriptionBoxStateContext.Provider
			value={useSubscriptionBoxStateManager(step, homeScreenBoxState)}
		>
			{children}
		</SubscriptionBoxStateContext.Provider>
	)
}

// Returns current array index
export const useCurrentSubscriptionBoxStep = (): SubscriptionBoxStep => {
	const { subscriptionBoxStep } = useContext(SubscriptionBoxStateContext)
	return subscriptionBoxStep
}

// Returns current state of home screen
export const useHomeScreenBoxState = () => {
	const { homeScreenBoxState } = useContext(SubscriptionBoxStateContext)
	return homeScreenBoxState
}

// Go to next step in subscription box flow
export const useNextSubscriptionBoxStep = () => {
	const { nextSubscriptionBoxStep } = useContext(SubscriptionBoxStateContext)
	return nextSubscriptionBoxStep
}

// Go to previous step in subscription box flow
export const usePreviousSubscriptionBoxStep = () => {
	const { previousSubscriptionBoxStep } = useContext(SubscriptionBoxStateContext)
	return previousSubscriptionBoxStep
}

// Go to a specific subscription box step
export const useSpecificSubscriptionBoxStep = () => {
	const { specificSubscriptionBoxStep } = useContext(SubscriptionBoxStateContext)
	return specificSubscriptionBoxStep
}

// How the user chose to proceed with box - customized or not
export const useCustomizableSelectionType = () => {
	const { customizableSelectionType } = useContext(SubscriptionBoxStateContext)
	return customizableSelectionType
}

// Set how the user chose to proceed with box - customized or not
export const useSetCustomizableSelectionType = () => {
	const { setCustomizableSelectionType } = useContext(SubscriptionBoxStateContext)
	return setCustomizableSelectionType
}

// TODO: maybe move to ProductsPickerOverlay component as it's only used there right now
// Gets product picker overlay state
export const useProductPickerOverlayState = () => {
	const { productPickerOverlayState } = useContext(SubscriptionBoxStateContext)
	return productPickerOverlayState
}

// Updates product picker overlay state
export const useUpdateProductPickerOverlayState = () => {
	const { setProductPickerOverlayState } = useContext(SubscriptionBoxStateContext)
	return setProductPickerOverlayState
}
