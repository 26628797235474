import { cartDetailsFragment } from '../queries/get-cart-query'

/**
 * @see https://shopify.dev/api/storefront/2022-01/mutations/cartLinesUpdate
 */
const cartLinesUpdateMutation = /* GraphQL */ `
	mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			userErrors {
				code
				field
				message
			}
			cart {
				...cartDetails
			}
		}
	}

	${cartDetailsFragment}
`
export default cartLinesUpdateMutation
