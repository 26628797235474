import { useEffect, useRef, useState } from 'react'
import type { Dispatch, RefObject, SetStateAction } from 'react'

import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useClickAway } from 'react-use'

import CommonTextNodes from '@data/i18n/common.json'
import NavTextNodes from '@data/i18n/nav.json'
import urls from '@data/i18n/urls.json'
import membersMarketData from '@data/memberMarket.json'

import { CUSTOMIZABLE_BOX_HANDLE, colUrl, lang } from '@lib/constants'
import type { CartItem } from '@lib/types/cart'
import { splitUrl } from '@lib/utils/helpers'

import Logo from '@components/global/Logo'
import BagShoppingIcon from '@components/icons/bag-shopping'
import MagnifyingGlassIcon from '@components/icons/magnifying-glass'
import UserIcon from '@components/icons/user'

const SearchPage = dynamic(() => import('@components/search/SearchPage'))

type DesktopNavParams = {
	toggleCart: Dispatch<SetStateAction<boolean>>
	menuData: any
	cartItems: CartItem[] | null | undefined
	itemCount: number
	navWrapperRef: RefObject<HTMLDivElement>
	isLoggedIn: boolean
}
export default function DesktopNav({
	toggleCart,
	menuData,
	cartItems,
	itemCount,
	navWrapperRef,
	isLoggedIn
}: DesktopNavParams) {
	const [openDrawer, setOpenDrawer] = useState<string | null>(null)

	const searchInputRef = useRef<HTMLInputElement>(null)
	const router = useRouter()

	const currentDate = new Date()
	const startDate = new Date(membersMarketData.start_date)
	const endDate = new Date(membersMarketData.end_date)
	const isMambersMarketCurrentlyOpen =
		currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()

	const [loadSearchDrawer, setLoadSearchDrawer] = useState(false)
	useEffect(() => {
		if (openDrawer === 'search') {
			if (!loadSearchDrawer) {
				setLoadSearchDrawer(true)
			}
		}
	}, [openDrawer, loadSearchDrawer])

	useClickAway(navWrapperRef, () => setOpenDrawer(null))

	// Handle close menu on route change
	useEffect(() => {
		const handleRouteChange = () => {
			setOpenDrawer(null)
		}

		router.events.on('routeChangeStart', handleRouteChange)

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<nav className="container relative z-50 flex items-center justify-between max-lg:hidden">
				<Link href="/" prefetch={false}>
					<a className="py-4  -mb-1.5">
						<Logo className="w-20 xl:w-28 fill-bbx-style" />
					</a>
				</Link>
				{/* TODO: pull in categories and use for links */}
				<div className="flex items-center">
					<div className="flex items-stretch">
						{/* Top level nav */}

						{lang === 'en-us' ? (
							<>
								{/* Hard-coded links for launch */}
								{/* <Link href={`${colUrl}/best-sellers`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/best-sellers`)
												? 'current font-medium'
												: ''
										}`}
									>
										Bestsellers
									</a>
								</Link>
								<Link href={`${colUrl}/makeup`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/makeup`) ? 'current font-medium' : ''
										}`}
									>
										Makeup
									</a>
								</Link>
								<Link href={`${colUrl}/hair`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/hair`) ? 'current font-medium' : ''
										}`}
									>
										Hair
									</a>
								</Link>
								<Link href={`${colUrl}/skincare`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/skincare`) ? 'current font-medium' : ''
										}`}
									>
										Skin
									</a>
								</Link>

								<Link href={`${colUrl}/body`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/body`) ? 'current font-medium' : ''
										}`}
									>
										Body
									</a>
								</Link> */}
								<Link href="/all-plans">
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`/all-plans`) ? 'current font-medium' : ''
										}`}
									>
										Subscription Box
									</a>
								</Link>
								<Link href="/members-market">
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`/members-market`) ? 'current font-medium' : ''
										}`}
									>
										{/* <i className="fa-thin fa-store" /> */}
										{isMambersMarketCurrentlyOpen && (
											<span className="bg-[#72BF95] text-white font-bold text-[12px] px-[6px] mr-2">
												LIVE
											</span>
										)}
										Members Market
									</a>
								</Link>

								<Link href={`${colUrl}/gifting`}>
									<a
										className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
											router.asPath.startsWith(`${colUrl}/gifting`) ? 'current font-medium' : ''
										}`}
									>
										Gifting
									</a>
								</Link>
							</>
						) : (
							<>
								{menuData.items.map((item: DesktopNavParams['menuData']) => {
									const itemType = item?.url?.split('/').pop()
									const path = `/collections/${itemType}`
									return (
										/* Menu items with collections only */
										// eslint-disable-next-line react/jsx-key
										<Link href={path} key={itemType}>
											<a
												className={`nav-item bottom-line-current lg:px-4 xl:px-5 py-7 ${
													router.asPath.startsWith(path) ? 'current font-medium' : ''
												}`}
											>
												{item.title}
											</a>
										</Link>
									)
								})}
							</>
						)}
					</div>
					{/* The Mag */}
					<div className="mx-2 border-x border-gray-350 lg:mr-6">
						<Link href={NavTextNodes.theMagUrl} prefetch={false}>
							<a
								className={`px-5 py-[1.875rem] xl:px-6 nav-item bottom-line-current ${
									!openDrawer && router.asPath.startsWith(NavTextNodes.theMagUrl)
										? 'current font-medium'
										: ''
								}`}
							>
								{NavTextNodes.theMag}
							</a>
						</Link>
					</div>

					<div className="flex items-stretch">
						{/* Profile */}
						{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
						<Link href={urls.subNav.profile.url} prefetch={false}>
							<a
								title={isLoggedIn ? 'View your profile' : 'Sign in to your account'}
								className={`rounded flex items-center px-4 h-20 nav-item bottom-line-current ${
									isLoggedIn ? 'bg-bbx-pearl-light text-bbx-spark' : ''
								} ${
									!openDrawer && router.asPath.startsWith(urls.subNav.profile.url) ? 'current' : ''
								}`}
							>
								<UserIcon />
							</a>
						</Link>
						{/* Search */}
						<button
							type="button"
							title="Search Birchbox"
							className={`flex items-center px-4 h-20 nav-item bottom-line-current ${
								openDrawer === 'search' || router.pathname === '/search' ? 'current' : ''
							}`}
							onClick={() => {
								if (openDrawer === 'search' || router.pathname === '/search') {
									setOpenDrawer(null)
								} else {
									setOpenDrawer('search')
								}
							}}
						>
							{/* <a className="flex items-center px-2 xl:px-4 nav-item"> */}
							<MagnifyingGlassIcon classes="w-[17px] fill-bbx-style" />
						</button>
						{/* Cart */}
						<button
							className={`flex items-center px-4 h-20 nav-item rounded transition-colors duration-300 bottom-line-current pr-1 ${
								Array.isArray(cartItems) && cartItems?.length > 0 ? 'text-bbx-spark' : ''
							}`}
							title="View your cart"
							type="button"
							onClick={() => toggleCart((prev) => !prev)}
						>
							<BagShoppingIcon classes="w-4 fill-bbx-style" />

							{/* Items in cart count */}
							<span
								className={`w-6 py-1 ml-2 -mb-1 font-medium text-center rounded ${
									itemCount ? 'bg-bbx-pearl-light' : ''
								}`}
							>
								{itemCount || ''}
							</span>
						</button>
					</div>
					{lang !== 'en-us' && lang !== 'en-gb' && lang !== 'es-es' && (
						<Link href={`/${CUSTOMIZABLE_BOX_HANDLE}`} prefetch={false}>
							<a className="px-8 button button-primary lg:ml-4">{CommonTextNodes.nav.getBoxCTA}</a>
						</Link>
					)}
				</div>
			</nav>

			{/* Search dropdown */}
			<div className={`desktop-nav-drawer pt-6 pb-8 ${openDrawer === 'search' ? 'is-open' : ''}`}>
				{loadSearchDrawer ? <SearchPage inputRef={searchInputRef} type="compact" /> : null}
			</div>

			{/* Desktop nav */}
			{lang !== 'en-us' &&
				menuData.items.map((item: DesktopNavParams['menuData']) => {
					// TODO: include Featured for US
					// Should show the advanced nav features
					const shouldShowImages = Boolean(
						lang !== 'en-us' &&
							(lang === 'en-gb' ? item.title.trim() === 'Shop' : item.title.trim() === 'Especiales')
					)

					return (
						<div
							className={`desktop-nav-drawer ${openDrawer === item?.id ? 'is-open' : ''}`}
							key={item.id}
						>
							{Array.isArray(item?.items) && (
								<div
									className={`container ${
										shouldShowImages ? 'grid grid-cols-4 gap-8' : 'flex gap-x-14'
									}`}
								>
									{item.items.map((secondLevelItem: DesktopNavParams['menuData']) => {
										const secondLevelItemUrl = splitUrl(secondLevelItem?.url)

										return (
											<div className="flex flex-col gap-y-2.5" key={secondLevelItem?.id}>
												{secondLevelItemUrl?.startsWith('/') ? (
													<Link href={secondLevelItemUrl} key={secondLevelItem.id}>
														<a className="text-lg font-medium">
															{shouldShowImages && secondLevelItem.image && (
																<div className="relative w-full h-[160px] mb-3">
																	<Image
																		src={secondLevelItem.image.url}
																		alt={secondLevelItem.title}
																		layout="fill"
																		objectFit="cover"
																	/>
																</div>
															)}
															<span className="">{secondLevelItem.title}</span>
														</a>
													</Link>
												) : (
													<span className="text-lg font-medium">{secondLevelItem.title}</span>
												)}

												{Array.isArray(secondLevelItem?.items) &&
													secondLevelItem.items.map(
														(thirdLevelItem: DesktopNavParams['menuData']) => {
															const thirdLevelItemUrl = splitUrl(thirdLevelItem?.url)

															if (thirdLevelItemUrl)
																return (
																	<Link href={thirdLevelItemUrl} key={thirdLevelItem?.id}>
																		<a className="nav-item">{thirdLevelItem?.title}</a>
																	</Link>
																)

															return (
																<span key={thirdLevelItem?.id} className="nav-item">
																	{thirdLevelItem.title}
																</span>
															)
														}
													)}
											</div>
										)
									})}
								</div>
							)}
						</div>
					)
				})}
		</>
	)
}
