// eslint-disable-next-line import/prefer-default-export
export const HIGHLIGHT_ICONS = [
	{
		name: 'Cruelty-Free',
		icon: 'rabbit'
	},
	{
		name: 'Brightening',
		icon: 'sun'
	},
	{
		name: 'Clean',
		icon: 'soap'
	},
	{
		name: 'Aromatherapy',
		icon: 'eye-dropper-half'
	},
	{
		name: 'Long lasting',
		icon: 'timer'
	},
	{
		name: 'Anti-Aging',
		icon: 'clock-rotate-left'
	},
	{
		name: 'Refreshing',
		icon: 'sparkles'
	},
	{
		name: 'Exclusive',
		icon: 'stars'
	},
	{
		name: 'Color Enhancing',
		icon: 'palette'
	},
	{
		name: 'Sun Protection',
		icon: 'eclipse'
	},
	{
		name: 'Under-Eye Brightening',
		icon: 'eye'
	},
	{
		name: 'Detangling',
		icon: 'wave-triangle'
	},
	{
		name: 'Smoothing',
		icon: 'horizontal-rule'
	},
	{
		name: 'Shine',
		icon: 'lightbulb-on'
	},
	{
		name: 'Exfoliating',
		icon: 'soap'
	},
	{
		name: 'Gluten Free',
		icon: 'wheat-slash'
	},
	{
		name: 'Waterproof / Water resistant',
		icon: 'water-arrow-down'
	},
	{
		name: 'Paraben Free',
		icon: 'vial-circle-check'
	},
	{
		name: 'Curling',
		icon: 'user-hair-buns'
	},
	{
		name: 'Volumizing',
		icon: 'balloon'
	},
	{
		name: 'Vegan',
		icon: 'leaf'
	},
	{
		name: 'Sulfate Free',
		icon: 'flask'
	},
	{
		name: 'Silicone Free',
		icon: 'flask'
	},
	{
		name: 'Phthalate Free',
		icon: 'flask'
	},
	{
		name: 'Hydrating',
		icon: 'droplet'
	},
	{
		name: 'Moisturizing',
		icon: 'droplet'
	},
	{
		name: 'Oil Free',
		icon: 'droplet-slash'
	},
	{
		name: 'Oil-Free',
		icon: 'droplet-slash'
	},
	{
		name: 'Light',
		icon: 'feather-pointed'
	},
	{
		name: 'Sensitive Skin',
		icon: 'spa'
	},
	{
		name: 'All Skin',
		icon: 'people'
	},
	{
		name: 'Not Tested On Animals',
		icon: 'sheep'
	},
	{
		name: 'More Volume',
		icon: 'arrows-maximize'
	},
	{
		name: 'Lengthens',
		icon: 'arrows-left-right'
	},
	{
		name: 'Lengthening',
		icon: 'arrows-left-right'
	},
	{
		name: 'Volumizing',
		icon: 'arrows-maximize'
	},
	{
		name: 'Full',
		icon: 'arrows-maximize'
	},
	{
		name: 'Pore Minimizing',
		icon: 'circle-dashed'
	}
]
