import React from 'react'

import { useToasts } from '@context/useToast'

export default function ToastView() {
	const { toasts, setToasts } = useToasts()

	return (
		<div
			className={`fixed bottom-20 flex flex-col gap-y-2 right-4 transition-all z-50 ${
				toasts.length > 0 ? '' : 'pointer-events-none opacity-100 translate-y-5'
			}`}
		>
			{toasts.map((toastContent) => (
				<div
					key={toastContent.key}
					className="overflow-hidden shadow-lg shadow-bbx-spark/20 w-80 border border-gray-200"
				>
					<div className="p-5 bg-white">
						<div className="flex justify-between items-start">
							{/* Toast title */}
							<h3 className="font-serif text-xl mb-3">{toastContent.title}</h3>

							{/* Close toast button */}
							<button
								aria-label="Close Toast"
								type="button"
								className="p-1 -mt-1 "
								onClick={() => {
									setToasts(toasts.filter((t) => t.key !== toastContent.key))
								}}
							>
								<i className="fa-thin fa-times" />
							</button>
						</div>

						{/* Toast description */}
						<p className="text-sm leading-5">{toastContent.message}</p>
					</div>
					<hr className="h-1 bg-bbx-spark w-full border-none" />
				</div>
			))}
		</div>
	)
}
