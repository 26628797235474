import { lang } from '@lib/constants'

export const lastUrlSegment = (string: string | undefined) =>
	string ? string.substring(string.lastIndexOf('/') + 1) : null

export const splitUrl = (url: string): string | null => {
	const relativeUrl =
		lang === 'es-es'
			? `/categorias${url.split('myshopify.com/collections', 2)[1]}`
			: url.split('myshopify.com', 2)[1]

	if (relativeUrl.includes('undefined')) {
		return null
	}

	return relativeUrl
}
