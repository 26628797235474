import React, { useEffect, useState, useRef } from 'react'
import { PrismicRichText } from '@prismicio/react'
import promoBarData from '@data/promoBar.json'

export default function PromoBar() {
	const [scrollPosition, setScrollPosition] = useState(0)
	
	useEffect(() => {
		const handleScroll = () => {
			setScrollPosition(window.scrollY)
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<div className={`promo-bar-wrapper animate-appear ${scrollPosition > 50 ? 'hide' : ''}`}>
			<div className="promo-bar-content">
                <PrismicRichText field={promoBarData.promo_bar_text} />
			</div>
		</div>
	)
}