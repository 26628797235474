import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import type { ReactNode } from 'react'

import type { Session } from 'next-auth'

// eslint-disable-next-line
import type { default as Braze } from '@braze/web-sdk'

type BrazeContextType = {
	brazeFunction: () => Promise<typeof Braze | undefined>
	getBrazeExternalId: (session: Session | null) => string
}

// Braze Context
const getBrazeExternalId = (session: Session | null) =>
	session?.user?.customer?.brazeExternalId?.value as string

export const BrazeContext = createContext<BrazeContextType>({
	brazeFunction: async () => undefined,
	getBrazeExternalId
})

// Use braze function
export default function useBrazeIntegration() {
	const { brazeFunction } = useContext(BrazeContext)

	return { brazeFunction, getBrazeExternalId }
}

// Braze Provider
export function BrazeProvider({ children }: { children: ReactNode }) {
	const [brazeImport, setBrazeImport] = useState<typeof Braze>()

	const brazeFunction = useCallback(async () => {
		let braze = brazeImport

		if (!brazeImport) {
			braze = await import('@braze/web-sdk')

			if (process.env.NEXT_PUBLIC_BRAZE_TOKEN_CLIENT) {
				braze.initialize(process.env.NEXT_PUBLIC_BRAZE_TOKEN_CLIENT, {
					baseUrl: 'sdk.iad-05.braze.com',
					enableSdkAuthentication: true,
					allowUserSuppliedJavascript: true
				})
				// optionally show all in-app messages without custom handling
				braze.automaticallyShowInAppMessages()
			}

			setBrazeImport(braze)
		}

		return braze
	}, [brazeImport])

	// Memoize context value
	const contextValue = useMemo(() => ({ brazeFunction, getBrazeExternalId }), [brazeFunction])

	return <BrazeContext.Provider value={contextValue}>{children}</BrazeContext.Provider>
}
