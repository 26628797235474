const getCustomerQuery = /* GraphQL */ `
	query getCustomer($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			id
			firstName
			phone
			lastName
			phone
			email
			acceptsMarketing
			createdAt
			defaultAddress {
				id
				country
				company
				address1
				address2
				city
				zip
				province
			}
			brazeExternalId: metafield(namespace: "customer", key: "braze_external_id") {
				id
				value
			}
			tags
		}
	}
`

export default getCustomerQuery
