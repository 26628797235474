import Cookies from 'js-cookie'

import {
	SHOPIFY_CART_ID_COOKIE,
	SHOPIFY_CHECKOUT_URL_COOKIE,
	SHOPIFY_COOKIE_EXPIRE
} from '@lib/constants'
import fetcher from '@lib/fetcher'
import cartCreateMutation from '@lib/mutations/cart-create'

import { ShopifyWebStorefrontClient } from '@components/cart/OnwardStorefrontClient'

// FIXME: types
async function createCart(lineItems: any[]): Promise<any> {
	const { cartCreate } = await fetcher({
		query: cartCreateMutation,
		variables: {
			input: { lines: lineItems }
		}
	})

	const cart = cartCreate?.cart

	if (cart) {
		const options = {
			expires: SHOPIFY_COOKIE_EXPIRE
		}

		Cookies.set(SHOPIFY_CART_ID_COOKIE, cart?.id, options)

		if (cart?.webUrl) {
			Cookies.set(SHOPIFY_CHECKOUT_URL_COOKIE, cart.webUrl, options)
		}
	}

	if (window.onwardApp && window.onwardClient) {
		window.onwardApp.cartChanged(ShopifyWebStorefrontClient.parseCart(cart))
	}

	return cart
}

export default createCart
