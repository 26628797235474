import type { Breadcrumbs } from '@lib/types'
import type { Product as ProductType } from '@lib/types/product'

const getGtmEcommerceProduct = ({
	product,
	price,
	shippingMethod = undefined,
	quantity = 1,
	productBreadcrumb = [],
	isSubscriptionBox = false
}: {
	product: Partial<ProductType>
	price?: string
	quantity?: number

	// dimension3
	shippingMethod?: string

	// dimension5: <top_navigation_category> (example: skin, wellness, …)
	// category <low_navigation_category>
	productBreadcrumb?: Breadcrumbs | null
	isSubscriptionBox: boolean
}) => {
	let category = productBreadcrumb?.length
		? productBreadcrumb[productBreadcrumb.length - 1]?.title
		: ''
	let dimension5 = productBreadcrumb?.length ? productBreadcrumb[0]?.title : ''
	let productType = 'shop'
	if (isSubscriptionBox) {
		category = 'membership'
		dimension5 = 'subscriptions'
		productType = 'subscription'
	}
	// dimension4: subscription *or* shop
	const ecommProduct = {
		id: product?.id ? product.id.split('gid://shopify/Product/')?.[1] : undefined,
		name: product?.name,
		price: price ? parseFloat(price) : undefined,
		brand: product?.vendor,
		category,
		dimension3: shippingMethod,
		dimension4: productType,
		dimension5,
		quantity
	}

	return ecommProduct
}

export default getGtmEcommerceProduct
