import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { SessionProvider } from 'next-auth/react'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { BrazeProvider } from '@context/useBrazeIntegration'
import { ToastProvider } from '@context/useToast'
import MainLayout from '@layouts/MainLayout'
import { PrismicPreview } from '@prismicio/next'
import { SWRConfig } from 'swr'

import { GTM_ID, pageview } from '@lib/analytics/gtm'
import { lang } from '@lib/constants'

import '@styles/global.scss'

declare global {
	interface Window {
		zE: any
	}
}

const ProfileOrAuth = dynamic(() => import('@components/profile/layout/ProfileOrAuth'))

type ExtendedAppProps = {
	pageProps: any
	Component: AppProps['Component'] & { Layout?: any }
}

function MyApp({ Component, pageProps }: ExtendedAppProps) {
	const router = useRouter()

	useEffect(() => {
		window.onload = () => {
			// asPath instead of pathname to ensure correct url
			pageview(router?.asPath)
		}

		router.events.on('routeChangeComplete', pageview)
		return () => {
			router.events.off('routeChangeComplete', pageview)
		}
	}, [router])

	const isProfilePage = router.pathname.startsWith('/profile')

	const Children = ({ children }: { children: ReactNode }) => children
	const NestedLayout = Component?.Layout || Children

	return (
		<>
			<DefaultSeo
				titleTemplate="%s | Birchbox"
				description="Birchbox believes in easy to use products that work – and make you look and feel great. Birchbox's subscription service makes it easy to find those products."
				defaultTitle="Beauty Subscriptions | Birchbox"
				openGraph={{
					type: 'website',
					locale: 'en_US',
					url: 'https://www.birchbox.com/',
					site_name: 'Birchbox',
					title: 'Birchbox: Beauty Subscriptions',
					description: `Birchbox believes in easy to use products that work – and make you look and feel great. Birchbox's subscription service makes it easy to find those products.`,
					images: [
						{
							url: 'https://cdn.raster.app/birchbox/51LU9DtGI8?ixlib=js-3.6.0&height=630&width=1200&compress=false&fm=jpg&q=100&s=9fac8b8273f553de8d76fe6a4413da4f',
							width: 1200,
							height: 630,
							alt: 'Birchbox logo',
							type: 'image/jpeg'
						}
					]
				}}
				twitter={{
					handle: '@birchbox',
					site: '@site',
					cardType: 'summary_large_image'
				}}
			/>

			<SessionProvider session={pageProps?.session} refetchInterval={0}>
				{/* Font Awesome - linked to reyjay@retentionbrands.com */}

				<Script strategy="afterInteractive" src="https://kit.fontawesome.com/755e5d98bb.js" />

				{/* Google Tag Manager - Global base code (moved to document/head 20231113 --CM) */}

				{lang === 'en-us' && (
					// https://nextjs.org/docs/basic-features/script#executing-code-after-loading-onload
					<Script
						id="ze-snippet"
						strategy="lazyOnload"
						src="https://static.zdassets.com/ekr/snippet.js?key=07748884-9a35-49cb-8d69-f1c20899ec59"
						onLoad={() => {
							window.zE(() => {
								window.zE.setHelpCenterSuggestions({ search: 'widget' })
							})
						}}
						onError={() => {
							console.log('Error loading Zendesk')
						}}
					/>
				)}

				<SWRConfig
					value={{
						// disable revalidateOnFocus and revalidateIfStale for every SWR hook
						// that is called after this
						revalidateOnFocus: false,
						revalidateIfStale: false
					}}
				>
					<BrazeProvider>
						<ToastProvider>
							<MainLayout>
								<PrismicPreview repositoryName="birchbox">
									{isProfilePage ? (
										<ProfileOrAuth>
											<NestedLayout>
												<Component {...pageProps} />
											</NestedLayout>
										</ProfileOrAuth>
									) : (
										<Component {...pageProps} />
									)}
								</PrismicPreview>
							</MainLayout>
						</ToastProvider>
					</BrazeProvider>
				</SWRConfig>
			</SessionProvider>
		</>
	)
}

export default MyApp
