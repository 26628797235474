import { Fragment, useEffect, useState } from 'react'
import type { ChangeEvent } from 'react'

import {
	useAnimateInReady,
	useHandleSurveySubmissions,
	useSetAnimationReady,
	useSetNextButtonDisabled,
	useSurveyState
} from '@context/subscription-box/useSurvey'
import { Transition } from '@headlessui/react'

import type { SurveyQuestion } from '@lib/types/subscription-box'

function getRangeStepSize(steps: number, rangeMaxValue: number) {
	if (!Array.isArray(steps) || !rangeMaxValue) return 1

	const rangeStepSize = rangeMaxValue / (steps.length - 1)

	return rangeStepSize
}

type Step = {
	value: number
	label: string
}

type RangeSliderParams = {
	surveyQuestion: SurveyQuestion
	isRequired?: boolean
	index: number
}
export default function RangeSlider({ surveyQuestion, isRequired, index }: RangeSliderParams) {
	const { currentIndex, translateDirection } = useSurveyState()
	const handleSurveySubmissions = useHandleSurveySubmissions()
	const animateInReady = useAnimateInReady()
	const setAnimateInReady = useSetAnimationReady()
	const setNextButtonDisabled = useSetNextButtonDisabled()

	const { displayOptions } = surveyQuestion || {}

	const { rangeMaxValue, rangeMinValue, steps } = displayOptions || {}

	const rangeStepSize = getRangeStepSize(steps, rangeMaxValue)

	const getActiveLabel = (value: string): string => {
		const activeStep = steps?.find((step: Step) => `${step?.value}` === value)

		return activeStep?.label || ''
	}

	// Get the middle index of steps array
	const middleOfRangeSliderIndex = Math.round((steps.length - 1) / 2)

	// Initial range value
	const initialRangeValue = Array.isArray(steps) ? `${steps[middleOfRangeSliderIndex]?.value}` : '0'

	// Range value
	const [rangeVal, setRangeVal] = useState(initialRangeValue)

	// Active label value
	const [activeLabel, setActiveLabel] = useState(getActiveLabel(initialRangeValue))

	// On range value change or when range is current question, set survey submissions
	useEffect(() => {
		if (index === currentIndex) {
			handleSurveySubmissions(surveyQuestion, {
				optionValue: rangeVal
			})
		}
	}, [rangeVal, handleSurveySubmissions, index, currentIndex])

	// Set next button disabled=false when current question is RangeSlider - (range input always has a value)
	useEffect(() => {
		if (index === currentIndex) {
			setNextButtonDisabled(false)
		}
	}, [index, currentIndex, setNextButtonDisabled])

	const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e?.target as HTMLInputElement

		if (target?.value) {
			// Set current range value
			setRangeVal(target.value)

			// Set active label state
			setActiveLabel(getActiveLabel(target.value))
		}
	}

	return (
		<Transition
			show={index === currentIndex && animateInReady}
			as={Fragment}
			afterLeave={() => {
				setAnimateInReady(true)
			}}
			enter="transition ease-in-out duration-[400ms] delay-[400ms]"
			enterFrom={`opacity-0 ${
				translateDirection === 'forwards' ? 'translate-x-1/3' : '-translate-x-1/3'
			}`}
			enterTo="opacity-100 translate-x-0"
			leave="transition ease-in-out duration-[400ms]"
			leaveFrom="opacity-100 translate-x-0"
			leaveTo={`opacity-0 ${
				translateDirection === 'forwards' ? '-translate-x-1/3' : 'translate-x-1/3'
			}`}
		>
			<div className="flex flex-col skin-sensitivity">
				{/* Survey question body */}
				{surveyQuestion?.body && (
					<h3 className="text-lg font-medium text-center md:text-xl">{surveyQuestion.body}</h3>
				)}

				{activeLabel && (
					<p className="mt-12 text-sm font-medium text-center text-gray-500 lg:mt-16 sm:text-base md:text-lg">
						{activeLabel}
					</p>
				)}

				{surveyQuestion?.displayOptions && (
					<div className="relative mt-10 mb-40 md:my-20 max-w-[800px] w-full self-center h-8 flex items-center">
						<input
							type="range"
							min={rangeMinValue}
							max={rangeMaxValue}
							step={rangeStepSize}
							value={rangeVal}
							onChange={handleSliderChange}
							className="range-slider"
						/>

						{/* Range tick marks */}
						<ul
							className="absolute inset-x-0 flex items-center justify-between bg-bbx-spark h-0.5 mx-4 z-10"
							aria-hidden="true"
						>
							{surveyQuestion?.displayOptions?.steps?.map((_: unknown, i: number) => (
								<li
									className="w-2 h-2 rounded-full bg-bbx-spark"
									// eslint-disable-next-line react/no-array-index-key
									key={i}
								/>
							))}
						</ul>
					</div>
				)}
			</div>
		</Transition>
	)
}
