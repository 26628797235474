/* eslint-disable no-return-await */

/* eslint-disable no-param-reassign */

/* eslint-disable class-methods-use-this */
import axios from 'axios'

import type { AddItemParams } from '@lib/cart/add-item'
import removeItems from '@lib/cart/remove-items'
import type { UpdateItemParams } from '@lib/cart/update-item'
import type { GetCartReturn } from '@lib/cart/use-cart'

/*
 * Represents the interface to the storefront
 */
export interface StorefrontClient {
	// Fetches the insurance product from your storefront
	// We recommend using a fixed url, such as using the product handle,
	// over an ID to be more resilent to the product being removed and re-added.
	fetchInsuranceProduct(): Promise<Product>
	// Fetches the current cart, with all the items.
	fetchCart(): Promise<Cart>
	// Clears the current cart.
	// Onward uses this when adding the onward product to make sure onward is at the bottom of the cart.
	// The widget clears the cart, adds the onward product, then re-adds anything that was previously in the cart.
	clearCart(): Promise<Cart>
	// Updates a specific line item in the cart.
	// Used to set the quantity of specific items to 0 to remove a previous onward product for example.
	updateCartLines(changes: UpdateCartLineChange[]): Promise<Cart>
	// Adds line items to the cart
	addCartLines(changes: AddCartLineChange[]): Promise<Cart>
}

/*
 * Represents the current cart for the customer
 */
export interface Cart {
	currency: string
	items: CartLine[]
}

/*
 * Represents a line item in the cart
 */
export interface CartLine {
	id?: string | number
	cost: Money
	quantity: number
	variant: ProductVariant
	// Optional, only required if your store has subscription items
	sellingPlanIdentifier?: number | string | null
	// Optional, only required if your store uses line item properties
	properties?: null | Record<string, any>
}

/*
 * Represents a product variant on the storefront
 */
export interface ProductVariant {
	id: number | string
	price: Money | null
	// Onward protection only applies to products that can be shipped,
	// this is how you tell us if the variant require shipping.
	requiresShipping?: boolean | null
}

/*
 * Represents a prodcut on the storefront
 */
export interface Product {
	id?: string
	variants: ProductVariant[]
}

/*
 * Represents a change to a sepecifc line item in the cart
 */
export interface UpdateCartLineChange {
	// ID of the line item that is being updated
	id?: number | string
	variantId?: number | string
	quantity: number
	// Optional, only required if your store uses line item properties
	properties?: null | Record<string, any>
	// Optional, only required if your store has subscription items
	sellingPlanIdentifier?: number | string | null
}

/*
 * Represents a line item to add to the cart
 */
export interface AddCartLineChange {
	variantId: number | string
	quantity: number
	// Optional, only required if your store uses line item properties
	properties?: null | Record<string, any>
	// Optional, only required if your store has subscription items
	sellingPlanIdentifier?: number | string | null
}

/*
 * Represents amount and currency
 */
export interface Money {
	amount: number
	currencyCode: null | string
}

export class ShopifyWebStorefrontClient implements StorefrontClient {
	static insuranceProductKey = 'onward-package-protection'

	static shopifyBaseUrl = `https://${process.env.NEXT_PUBLIC_STORE_DOMAIN || ''}`

	addItems: (items: AddItemParams[]) => Promise<any>

	getCart: () => Promise<GetCartReturn>

	updateItems: (items: UpdateItemParams[]) => Promise<void>

	constructor(
		getCart: () => Promise<GetCartReturn>,
		addItems: (items: AddItemParams[]) => Promise<any>,
		updateItems: (items: UpdateItemParams[]) => Promise<void>
	) {
		this.addItems = addItems
		this.getCart = getCart
		this.updateItems = updateItems
	}

	async fetchInsuranceProduct(): Promise<Product> {
		const res = await axios.get(
			`${ShopifyWebStorefrontClient.shopifyBaseUrl}/products/${ShopifyWebStorefrontClient.insuranceProductKey}.js`
		)
		const resp = {
			id: res.data.id,
			variants: res.data.variants.map(
				(variantJson: { id: { toString: () => any }; price: number }) => ({
					id: `gid://shopify/ProductVariant/${variantJson.id.toString()}`,
					price: { amount: variantJson.price / 100.0, currencyCode: null }
				})
			)
		}

		return resp
	}

	static parseCart(cart: any): Cart {
		if (!cart) {
			return {
				currency: 'USD',
				items: []
			}
		}
		const cartObject = {
			currency: cart.estimatedCost.totalAmount.currencyCode,
			items:
				cart.lines?.edges?.map((item: { node: any }) => ({
					id: item.node.id,
					cost: {
						amount: parseFloat(item.node.estimatedCost.totalAmount.amount),
						currencyCode: item.node.estimatedCost.totalAmount.currencyCode
					},
					quantity: item.node.quantity,
					properties: null, // TODO: map with attributes, is it necessary?
					sellingPlanIdentifier: item.node.sellingPlanAllocation
						? item.node.sellingPlanAllocation.sellingPlan.id
						: null,
					variant: {
						price: null,
						requiresShipping: item.node.merchandise.requiresShipping,
						id: item.node.merchandise.id
					}
				})) || []
		}

		return cartObject
	}

	async updateCartLines(changes: UpdateCartLineChange[]): Promise<Cart> {
		// TODO: refactor to graphql
		const items = changes.map((change) => ({
			id: change.id?.toString(),
			variantId: change.variantId?.toString(),
			quantity: change.quantity,
			sellingPlanId: change.sellingPlanIdentifier?.toString()
		}))
		await this.updateItems(items)

		return this.fetchCart()
	}

	async addCartLines(changes: AddCartLineChange[]): Promise<Cart> {
		const items = changes.map(({ variantId, quantity, sellingPlanIdentifier }) => ({
			variantId: variantId.toString(),
			quantity,
			sellingPlanId: sellingPlanIdentifier ? sellingPlanIdentifier.toString() : null
		}))

		const cart = await this.addItems(items)
		return ShopifyWebStorefrontClient.parseCart(cart.cart)
	}

	async fetchCart(): Promise<Cart> {
		const resp = await this.getCart()

		return ShopifyWebStorefrontClient.parseCart(resp.cart)
	}

	async clearCart(): Promise<Cart> {
		const resp = await this.getCart()
		const cart = await removeItems(resp.items!.map((item) => ({ id: item.id })))
		return ShopifyWebStorefrontClient.parseCart(cart.cart)
	}
}
